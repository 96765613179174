import React, { useRef, useEffect } from 'react';

// import scroll_object_data from '../Components/Data/scroll_object_data';

import tinder_ss_1 from '../Images/tinder_ss_1.png';
import tinder_ss_2 from '../Images/tinder_ss_2.png';
import zillowhome from '../Images/screenshots/zillowhome.jpg';
import demo_vid_for_3drenders from '../Images/3drender_view_render_1.mp4';
import lohom_vid from '../Images/lo-home_vid_4.mp4';
import check from '../Images/check.svg';
import music_icon from '../Images/music_icon.svg';
import album_art_about_you from '../Images/album_art_about_you.png';
import album_art_drink_to_that from '../Images/album_art_drink_to_that.png';
import album_art_stay from '../Images/album_art_stay.png';
import album_art_jc from '../Images/album_art_jc.png';
// import album_art_party from '../Images/album_art_party.png';
import album_art_flo from '../Images/album_art_flo.png';
// import bit_code_test_2 from '../Images/bit_code_test_2.mp4';
import songhatch_frontend_vid from '../Images/music_gen_frontend_1.1.mp4';
import computer_brain from '../Images/computer_brain.jpg';
import liam_face_1 from '../Images/liam_fudge_512.png';
import link_icon from '../Images/link.svg';

import song_example_1 from '../Sounds/example_1.wav';
import song_example_3 from '../Sounds/example_3.wav';
import song_example_4 from '../Sounds/example_4.wav';
import mobilemassage2 from '../Images/screenshots/mobilemassage2.jpg'
import bed2 from '../Images/Renders/bed2.jpg';
import cosey_bedroom_10 from '../Images/Renders/cosey_bedroom_10.png';
import tina_house_render_1 from '../Images/Renders/tina_house_render_1.png';

const code_image = 'https://images.pexels.com/photos/249798/pexels-photo-249798.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';

const scroll_object_data = {


	// INITIAL
		'hero_text': {
			'0': {l:70,w:100,t:34,h:100,br:0,deg:0,o:1,text:'hero_text'},
			// '10': {l:64,w:0,t:42,h:0,br:50,deg:0,o:1,lbl:'hero_text'},
			// '440': {l:64,w:0,t:-144,h:0,br:50,deg:0,o:0,lbl:'hero_text'},
		},
		'sub_hero_text': {
			'0': {l:70,w:100,t:51,h:100,br:0,deg:0,o:1,text:'sub_hero_text'},
			// '10': {l:64,w:0,t:60,h:0,br:50,deg:0,o:1,lbl:'sub_hero_text'},
			// '440': {l:64,w:0,t:-130,h:0,br:50,deg:0,o:0,lbl:'sub_hero_text'},
		},
		'hero_text_mobile': {
			'0': {l:0,w:100,t:64,h:100,br:0,deg:0,o:1,text:'hero_text_mobile'},
			// '10': {l:64,w:0,t:42,h:0,br:50,deg:0,o:1,lbl:'hero_text'},
			// '440': {l:64,w:0,t:-144,h:0,br:50,deg:0,o:0,lbl:'hero_text'},
		},
		'sub_hero_text_mobile': {
			'0': {l:10,w:80,t:78,h:100,br:0,deg:0,o:1,text:'sub_hero_text_mobile'},
			// '10': {l:64,w:0,t:60,h:0,br:50,deg:0,o:1,lbl:'sub_hero_text'},
			// '440': {l:64,w:0,t:-130,h:0,br:50,deg:0,o:0,lbl:'sub_hero_text'},
		},


	// LINKS
		portfolio_link: {
			'0': {l:20,w:0,t:10,h:0,br:50,deg:0,o:0,gradient:'link_gradient',lbl:'portfolio_link',content:'link_icon',link:'development'},

			'500': {l:60,w:0,t:50,h:0,br:50,deg:0,o:0,gradient:'link_gradient',lbl:'portfolio_link',content:'link_icon',link:'development'},
			'600': {l:40,w:40,t:30,h:40,br:50,deg:360,o:1,gradient:'link_gradient',lbl:'portfolio_link',content:'link_icon',link:'development'},

			'1600': {l:40,w:40,t:30,h:40,br:50,deg:360,o:1,gradient:'link_gradient',lbl:'portfolio_link',content:'link_icon',link:'development'},
			'1700': {l:60,w:0,t:50,h:0,br:50,deg:0,o:0,gradient:'link_gradient',lbl:'portfolio_link',content:'link_icon',link:'development'},
		},
		engine_link: {
			'0': {l:20,w:0,t:10,h:0,br:50,deg:0,o:0,gradient:'link_gradient',lbl:'engine_link',content:'link_icon',link:'engine'},

			'2000': {l:60,w:0,t:50,h:0,br:50,deg:0,o:0,gradient:'link_gradient',lbl:'engine_link',content:'link_icon',link:'engine'},
			'2100': {l:40,w:40,t:30,h:40,br:50,deg:360,o:1,gradient:'link_gradient',lbl:'engine_link',content:'link_icon',link:'engine'},

			'2800': {l:40,w:40,t:30,h:40,br:50,deg:360,o:1,gradient:'link_gradient',lbl:'engine_link',content:'link_icon',link:'engine'},
			'2900': {l:60,w:0,t:50,h:0,br:50,deg:0,o:0,gradient:'link_gradient',lbl:'engine_link',content:'link_icon',link:'engine'},
		},
		production_link: {
			'0': {l:20,w:0,t:10,h:0,br:50,deg:0,o:0,gradient:'link_gradient',lbl:'production_link',content:'link_icon',link:'production'},

			'3000': {l:60,w:0,t:50,h:0,br:50,deg:0,o:0,gradient:'link_gradient',lbl:'production_link',content:'link_icon',link:'production'},
			'3100': {l:40,w:40,t:30,h:40,br:50,deg:360,o:1,gradient:'link_gradient',lbl:'production_link',content:'link_icon',link:'production'},

			'4700': {l:40,w:40,t:30,h:40,br:50,deg:360,o:1,gradient:'link_gradient',lbl:'production_link',content:'link_icon',link:'production'},
			'4800': {l:60,w:0,t:50,h:0,br:50,deg:0,o:0,gradient:'link_gradient',lbl:'production_link',content:'link_icon',link:'production'},
		},
		rednering_link: {
			'0': {l:20,w:0,t:10,h:0,br:50,deg:0,o:0,gradient:'link_gradient',lbl:'rednering_link',content:'link_icon',link:'rendering'},

			'4980': {l:60,w:0,t:50,h:0,br:50,deg:0,o:0,gradient:'link_gradient',lbl:'rednering_link',content:'link_icon',link:'rendering'},
			'5080': {l:40,w:40,t:30,h:40,br:50,deg:360,o:1,gradient:'link_gradient',lbl:'rednering_link',content:'link_icon',link:'rendering'},

			'6200': {l:40,w:40,t:30,h:40,br:50,deg:360,o:1,gradient:'link_gradient',lbl:'rednering_link',content:'link_icon',link:'rendering'},
			'6300': {l:60,w:0,t:50,h:0,br:50,deg:0,o:0,gradient:'link_gradient',lbl:'rednering_link',content:'link_icon',link:'rendering'},
		},


	// HEADINGS
		'skills_heading': {
			'0': {l:4,w:100,t:8,h:100,br:0,deg:0,o:0,text:'skills_text'},
			'400': {l:4,w:100,t:8,h:100,br:0,deg:0,o:0,text:'skills_text'},
			'420': {l:4,w:100,t:8,h:100,br:0,deg:0,o:1,text:'skills_text'},
			'1500': {l:4,w:100,t:8,h:100,br:0,deg:0,o:1,text:'skills_text'},
			'1700': {l:4,w:100,t:8,h:100,br:0,deg:0,o:0,text:'skills_text'},
		},
		'generation_engine_heading': {
			'0': {l:4,w:100,t:8,h:100,br:0,deg:0,o:0,text:'generation_engine_heading'},
			'1960': {l:4,w:100,t:8,h:100,br:0,deg:0,o:0,text:'generation_engine_heading'},
			'2000': {l:4,w:100,t:8,h:100,br:0,deg:0,o:1,text:'generation_engine_heading'},

			'2800': {l:4,w:100,t:8,h:100,br:0,deg:0,o:1,text:'generation_engine_heading'},
			'2900': {l:4,w:100,t:-40,h:100,br:0,deg:0,o:0,text:'generation_engine_heading'},
		},
		'production_heading': {
			'0': {l:4,w:100,t:8,h:100,br:0,deg:0,o:0,text:'production_heading'},
			'3060': {l:4,w:100,t:8,h:100,br:0,deg:0,o:0,text:'production_heading'},
			'3200': {l:4,w:100,t:8,h:100,br:0,deg:0,o:1,text:'production_heading'},

			'4750': {l:4,w:100,t:8,h:100,br:0,deg:0,o:1,text:'production_heading'},
			'5000': {l:4,w:100,t:-40,h:100,br:0,deg:0,o:1,text:'production_heading'},
		},
		'threeDrender_heading': {
			'0': {l:4,w:100,t:8,h:100,br:0,deg:0,o:0,text:'threeDrender_heading'},
			'5000': {l:4,w:100,t:8,h:100,br:0,deg:0,o:0,text:'threeDrender_heading'},
			'5100': {l:4,w:100,t:8,h:100,br:0,deg:0,o:1,text:'threeDrender_heading'},

			'6000': {l:4,w:100,t:8,h:100,br:0,deg:0,o:1,text:'threeDrender_heading'},
			'6200': {l:4,w:100,t:8,h:100,br:0,deg:0,o:0,text:'threeDrender_heading'},
		},


	// MAIN components
		'phone_box': {
			// '0': {l:50,w:0,t:50,h:0,br:50,deg:0,o:0,content:'profile_pic'},
			'0': {l:38,w:24,t:38,h:24,br:50,deg:360,o:1,content:'profile_pic'},
			'30': {l:38,w:24,t:38,h:24,br:50,deg:360,o:1,content:'profile_pic'},
			'100': {l:38,w:24,t:38,h:24,br:50,deg:350,o:1,content:'profile_pic'},
			
			'160': {l:42,w:16,t:42,h:16,br:50,deg:0,o:1,content:'mobile_pic_1'},
			'200': {l:32,w:36,t:42,h:16,br:0,deg:0,o:1,content:'mobile_pic_1'},
			'240': {l:32,w:36,t:13,h:74,br:0,deg:0,o:1,content:'mobile_pic_1'},

			'480': {l:32,w:36,t:13,h:74,br:0,deg:0,o:1,content:'mobile_pic_1'},
			// '500': {l:26,w:36,t:13,h:74,br:0,deg:0,o:1,content:'mobile_pic_2'},
			'560': {l:10,w:42,t:6,h:88,br:0,deg:0,o:1,content:'mobile_pic_2'},

			'900': {l:10,w:42,t:6,h:88,br:0,deg:0,o:1,content:'mobile_pic_2'},
			'1060': {l:-90,w:42,t:6,h:88,br:0,deg:0,o:0,content:'mobile_pic_2'},
			// '1060': {l:10,w:0,t:8,h:0,br:0,deg:0,o:0,content:'mobile_pic_2'},
		},
		'phone_shadow': {
			'0': {l:38,w:24,t:38,h:24,br:50,deg:360,o:0,shadow:true},
			'30': {l:38,w:24,t:38,h:24,br:50,deg:360,o:0,shadow:true},
			'100': {l:38,w:24,t:38,h:24,br:50,deg:350,o:0,shadow:true},
			
			'160': {l:42,w:16,t:42,h:16,br:50,deg:0,o:0,shadow:true},
			'200': {l:32,w:36,t:42,h:16,br:0,deg:0,o:0,shadow:true},
			'240': {l:32,w:36,t:90,h:6,br:0,deg:0,o:0.7,shadow:true},

			'480': {l:32,w:36,t:90,h:6,br:0,deg:0,o:0.7,shadow:true},
			'560': {l:10,w:42,t:96,h:6,br:0,deg:0,o:0.7,shadow:true},

			'900': {l:10,w:42,t:96,h:6,br:0,deg:0,o:0.7,shadow:true},
			'910': {l:10,w:42,t:96,h:6,br:0,deg:0,o:0.2,shadow:true},
			'1060': {l:10,w:0,t:96,h:0,br:0,deg:0,o:0,shadow:true},
		},
		'computer_box': {
			'0': {l:90,w:0,t:92,h:0,br:5,deg:360,o:0,content:'lohom_vid'},

			'900': {l:90,w:0,t:92,h:0,br:5,deg:360,o:0.8,content:'lohom_vid'},
			'1060': {l:0,w:90,t:8,h:84,br:5,deg:360,o:1,content:'lohom_vid'},

			'1200': {l:0,w:90,t:8,h:84,br:5,deg:360,o:1,content:'lohom_vid'},
			'1700': {l:0,w:90,t:8,h:84,br:5,deg:360,o:1,content:'lohom_vid'},
			'2000': {l:0,w:90,t:-152,h:84,br:5,deg:360,o:1,content:'lohom_vid'},
		},

		'songhatch_frontend': {
			'0': {l:90,w:0,t:92,h:0,br:0,deg:360,o:0,br_pxl:12,content:'songhatch_frontend_vid',gradient:'white_background'},

			'900': {l:0,w:90,t:92,h:0,br:0,deg:360,o:1,br_pxl:12,content:'songhatch_frontend_vid',gradient:'white_background'},
			'1060': {l:0,w:90,t:26,h:54,br:0,deg:360,o:1,br_pxl:12,content:'songhatch_frontend_vid',gradient:'white_background'},

			'1200': {l:0,w:90,t:26,h:54,br:0,deg:360,o:1,br_pxl:12,content:'songhatch_frontend_vid',gradient:'white_background'},
			
			'1300': {l:0,w:90,t:26,h:54,br:0,deg:360,o:1,br_pxl:12,content:'songhatch_frontend_vid',gradient:'white_background'},
			'1400': {l:2,w:86,t:16,h:66,br:0,deg:360,o:1,br_pxl:12,content:'songhatch_frontend_vid',gradient:'white_background'},
			
			'1700': {l:2,w:86,t:16,h:66,br:0,deg:360,o:1,br_pxl:12,content:'songhatch_frontend_vid',gradient:'white_background'},
			'2000': {l:2,w:86,t:-122,h:66,br:0,deg:360,o:1,br_pxl:12,content:'songhatch_frontend_vid',gradient:'white_background'},
			'2100': {l:2,w:86,t:-152,h:66,br:0,deg:360,o:0,br_pxl:12,content:'songhatch_frontend_vid',gradient:'white_background'},
		},
		'songhatch_frontend_mobile': {
			'0': {l:90,w:0,t:92,h:0,br:0,deg:360,o:0,br_pxl:12,gradient:'song_hatch_bkg',content:'mobilemassage2'},

			'900': {l:0,w:90,t:92,h:0,br:0,deg:360,o:1,br_pxl:12,gradient:'song_hatch_bkg',content:'mobilemassage2'},
			'1060': {l:0,w:90,t:38,h:38,br:0,deg:360,o:1,br_pxl:12,gradient:'song_hatch_bkg',content:'mobilemassage2'},

			'1200': {l:0,w:90,t:38,h:38,br:0,deg:360,o:1,br_pxl:12,gradient:'song_hatch_bkg',content:'mobilemassage2'},
			
			'1300': {l:0,w:90,t:38,h:38,br:0,deg:360,o:1,br_pxl:12,gradient:'song_hatch_bkg',content:'mobilemassage2'},
			'1400': {l:2,w:86,t:38,h:38,br:0,deg:360,o:1,br_pxl:12,gradient:'song_hatch_bkg',content:'mobilemassage2'},
			
			'1700': {l:2,w:86,t:38,h:38,br:0,deg:360,o:1,br_pxl:12,gradient:'song_hatch_bkg',content:'mobilemassage2'},
			'2000': {l:2,w:86,t:-122,h:38,br:0,deg:360,o:1,br_pxl:12,gradient:'song_hatch_bkg',content:'mobilemassage2'},
			'2100': {l:2,w:86,t:-152,h:38,br:0,deg:360,o:0,br_pxl:12,gradient:'song_hatch_bkg',content:'mobilemassage2'},
		},
		'songhatch_frontend_shadow': {
			'0': {l:90,w:0,t:92,h:0,br:0,deg:360,o:0,br_pxl:12,shadow:true},

			'900': {l:0,w:90,t:92,h:0,br:0,deg:360,o:0,br_pxl:12,shadow:true},
			'1060': {l:0,w:90,t:84,h:6,br:0,deg:360,o:0.3,br_pxl:12,shadow:true},

			'1200': {l:0,w:90,t:84,h:6,br:0,deg:360,o:0.7,br_pxl:12,shadow:true},
			
			'1300': {l:0,w:90,t:84,h:6,br:0,deg:360,o:0.7,br_pxl:12,shadow:true},
			'1400': {l:2,w:86,t:84,h:6,br:0,deg:360,o:0.7,br_pxl:12,shadow:true},
			
			'1680': {l:2,w:86,t:84,h:6,br:0,deg:360,o:0.7,br_pxl:12,shadow:true},
			'1700': {l:2,w:86,t:84,h:6,br:0,deg:360,o:0,br_pxl:12,shadow:true},
		},

		'songhatch_backend': {
			'0': {l:90,w:0,t:92,h:0,br:50,deg:0,o:0,content:'computer_brain'},

			// '1000': {l:90,w:0,t:92,h:0,br:50,deg:0,o:0,content:'computer_brain'},
			// '1160': {l:15,w:60,t:20,h:60,br:50,deg:0,o:0,content:'computer_brain'},

			'1500': {l:15,w:60,t:20,h:60,br:50,deg:-360,o:0,content:'computer_brain'},
			'1700': {l:15,w:60,t:20,h:60,br:50,deg:0,o:1,content:'computer_brain'},

			'2000': {l:25,w:40,t:30,h:40,br:50,deg:360,o:1,content:'computer_brain'},

			'2800': {l:25,w:40,t:30,h:40,br:50,deg:720,o:1,content:'computer_brain'},
			'2900': {l:25,w:40,t:-70,h:40,br:50,deg:720,o:0,content:'computer_brain'},
		},
		'songhatch_backend_mobile': {
			'0': {l:90,w:0,t:92,h:0,br:50,deg:0,o:0,content:'computer_brain'},

			'1500': {l:30,w:30,t:45,h:30,br:50,deg:-360,o:0,content:'computer_brain'},
			'1700': {l:25,w:40,t:40,h:40,br:50,deg:0,o:1,content:'computer_brain'},

			'2000': {l:35,w:20,t:50,h:20,br:50,deg:360,o:1,content:'computer_brain'},

			'2800': {l:35,w:20,t:200,h:20,br:50,deg:720,o:1,content:'computer_brain'},
			'2900': {l:35,w:20,t:200,h:20,br:50,deg:720,o:0,content:'computer_brain'},
			// '2900': {l:35,w:20,t:-70,h:20,br:50,deg:720,o:0,content:'computer_brain'},
		},
		'songhatch_backend_shadow': {
			'0': {l:90,w:0,t:92,h:0,br:6,deg:0,o:0,shadow:true},

			'2000': {l:25,w:40,t:80,h:6,br:50,deg:0,o:0,shadow:true},
			'2100': {l:25,w:40,t:80,h:6,br:50,deg:0,o:0.7,shadow:true},


			'2800': {l:25,w:40,t:80,h:6,br:50,deg:0,o:0.7,shadow:true},
			'2900': {l:25,w:40,t:80,h:6,br:50,deg:0,o:0,shadow:true},
		},


		'engine_1': {
			'0': {l:30,w:4,t:30,h:4,br:50,deg:0,o:0,gradient:'white_background',lbl:'engine_1'},

			'2020': {l:70,w:1,t:50,h:1,br:50,deg:0,o:0,gradient:'white_background',lbl:'engine_1'},
			'2200': {l:30,w:4,t:30,h:4,br:50,deg:0,o:1,gradient:'white_background',lbl:'engine_1'},

			'2800': {l:30,w:4,t:30,h:4,br:50,deg:0,o:1,gradient:'white_background',lbl:'engine_1'},
			'2900': {l:30,w:4,t:-130,h:4,br:50,deg:0,o:0,gradient:'white_background',lbl:'engine_1'},
		},
		'engine_2': {
			'0': {l:28,w:4,t:50,h:4,br:50,deg:0,o:0,gradient:'white_background',lbl:'engine_2'},

			'2020': {l:70,w:1,t:50,h:1,br:50,deg:0,o:0,gradient:'white_background',lbl:'engine_2'},
			'2200': {l:28,w:4,t:50,h:4,br:50,deg:0,o:1,gradient:'white_background',lbl:'engine_2'},

			'2800': {l:30,w:4,t:50,h:4,br:50,deg:0,o:1,gradient:'white_background',lbl:'engine_2'},
			'2900': {l:30,w:4,t:-130,h:4,br:50,deg:0,o:0,gradient:'white_background',lbl:'engine_2'},
		},
		'engine_3': {
			'0': {l:30,w:4,t:70,h:4,br:50,deg:0,o:0,gradient:'white_background',lbl:'engine_3'},

			'2020': {l:70,w:1,t:50,h:1,br:50,deg:0,o:0,gradient:'white_background',lbl:'engine_3'},
			'2200': {l:30,w:4,t:70,h:4,br:50,deg:0,o:1,gradient:'white_background',lbl:'engine_3'},

			'2800': {l:30,w:4,t:70,h:4,br:50,deg:0,o:1,gradient:'white_background',lbl:'engine_3'},
			'2900': {l:30,w:4,t:-130,h:4,br:50,deg:0,o:0,gradient:'white_background',lbl:'engine_3'},
		},
		'engine_4': {
			'0': {l:36,w:4,t:10,h:4,br:50,deg:0,o:0,gradient:'white_background',lbl:'engine_4'},

			'2020': {l:70,w:1,t:50,h:1,br:50,deg:0,o:0,gradient:'white_background',lbl:'engine_4'},
			'2200': {l:35,w:4,t:10,h:4,br:50,deg:0,o:1,gradient:'white_background',lbl:'engine_4'},

			'2800': {l:30,w:4,t:10,h:4,br:50,deg:0,o:1,gradient:'white_background',lbl:'engine_4'},
			'2900': {l:30,w:4,t:-130,h:4,br:50,deg:0,o:0,gradient:'white_background',lbl:'engine_4'},
		},
		'engine_5': {
			'0': {l:36,w:4,t:90,h:4,br:50,deg:0,o:0,gradient:'white_background',lbl:'engine_5'},

			'2020': {l:70,w:1,t:50,h:1,br:50,deg:0,o:0,gradient:'white_background',lbl:'engine_5'},
			'2200': {l:35,w:4,t:90,h:4,br:50,deg:0,o:1,gradient:'white_background',lbl:'engine_5'},

			'2800': {l:30,w:4,t:90,h:4,br:50,deg:0,o:1,gradient:'white_background',lbl:'engine_5'},
			'2900': {l:30,w:4,t:-130,h:4,br:50,deg:0,o:0,gradient:'white_background',lbl:'engine_5'},
		},


	// ROUTES
		'landing_clr_block': {
			'0': {l:0,w:100,t:0,h:100,br:0,deg:0,o:1,gradient:'bgk_gradient1'},
			'600': {l:0,w:100,t:0,h:100,br:0,deg:0,o:1,gradient:'bgk_gradient1'},
			'800': {l:0,w:100,t:0,h:100,br:0,deg:0,o:1,gradient:'bgk_gradient1'},

			'1300': {l:0,w:100,t:0,h:100,br:0,deg:0,o:1,gradient:'bgk_gradient1'},
			'1400': {l:0,w:100,t:0,h:100,br:0,deg:0,o:1,gradient:'bgk_gradient1'},
			'1700': {l:0,w:100,t:0,h:100,br:0,deg:0,o:1,gradient:'bgk_gradient1'},
			'2000': {l:0,w:100,t:0,h:0,br:0,deg:0,o:0,gradient:'bgk_gradient1'},
		},

		'code_block': {
			'0': {l:0,w:100,t:100,h:100,br:0,deg:0,o:0,gradient:'dark_code'},

			'1700': {l:0,w:100,t:100,h:100,br:0,deg:0,o:0,gradient:'dark_code'},
			'2000': {l:0,w:100,t:0,h:100,br:0,deg:0,o:1,gradient:'dark_code'},

			// '2000': {l:0,w:100,t:0,h:100,br:0,deg:0,o:1,gradient:'dark_code'},

			'2900': {l:0,w:100,t:0,h:100,br:0,deg:0,o:1,gradient:'dark_code'},
			'3000': {l:0,w:100,t:-100,h:100,br:0,deg:0,o:0,gradient:'dark_code'},
		},
		'music_clr_block': {
			'0': {l:0,w:100,t:100,h:100,br:0,deg:0,o:0,gradient:'bgk_gradient1'},
			'2890': {l:0,w:100,t:100,h:100,br:0,deg:0,o:0,gradient:'bgk_gradient1'},
			'2900': {l:0,w:100,t:100,h:100,br:0,deg:0,o:1,gradient:'bgk_gradient1'},
			'3000': {l:0,w:100,t:0,h:100,br:0,deg:0,o:1,gradient:'bgk_gradient1'},


			'4800': {l:0,w:100,t:0,h:100,br:0,deg:0,o:1,gradient:'bgk_gradient1'},
			'5000': {l:0,w:100,t:-100,h:100,br:0,deg:0,o:1,gradient:'bgk_gradient1'},
		},

		'ending_clr_block': {
			'0': {l:0,w:100,t:100,h:100,br:0,deg:0,o:0,gradient:'ending_black_out'},
			'5890': {l:0,w:100,t:0,h:100,br:0,deg:0,o:0,gradient:'ending_black_out'},
			'5900': {l:0,w:100,t:0,h:100,br:0,deg:0,o:0,gradient:'ending_black_out'},
			'6000': {l:0,w:100,t:0,h:100,br:0,deg:0,o:1,gradient:'ending_black_out'},


			'6800': {l:0,w:100,t:0,h:100,br:0,deg:0,o:1,gradient:'ending_black_out'},
			'8000': {l:0,w:100,t:0,h:100,br:0,deg:0,o:1,gradient:'ending_black_out'},
		},


	// MUSIC GENERATION
		'music_engine_paragraph': {
			'0': {l:8,w:80,t:12,h:100,br:0,deg:0,o:0,text:'music_engine_paragraph'},
			'1900': {l:8,w:80,t:12,h:100,br:0,deg:0,o:0,text:'music_engine_paragraph'},
			'2100': {l:8,w:80,t:12,h:100,br:0,deg:0,o:1,text:'music_engine_paragraph'},

			'2800': {l:8,w:80,t:12,h:100,br:0,deg:0,o:1,text:'music_engine_paragraph'},
			'2900': {l:8,w:80,t:-80,h:100,br:0,deg:0,o:0,text:'music_engine_paragraph'},
		},
		'music_engine_screenshot': {
			'0': {l:8,w:80,t:32,h:60,br:0,deg:0,o:0,text:'music_engine_paragraph'},
			'1900': {l:8,w:80,t:32,h:60,br:0,deg:0,o:0,text:'music_engine_paragraph'},
			'2100': {l:8,w:80,t:32,h:60,br:0,deg:0,o:1,text:'music_engine_paragraph'},

			'2800': {l:8,w:80,t:32,h:60,br:0,deg:0,o:1,text:'music_engine_paragraph'},
			'2900': {l:8,w:80,t:-80,h:60,br:0,deg:0,o:0,text:'music_engine_paragraph'},
		},

		'music_engine_song_1': {
			'0': {l:10,w:60,t:30,h:0,br:0,deg:0,o:0,br_pxl:12,gradient:'engine_audio_display'},

			'2100': {l:44,w:2,t:49,h:2,br:50,deg:360,o:0,br_pxl:12,gradient:'engine_audio_display'},
			'2300': {l:40,w:10,t:45,h:10,br:50,deg:360,o:1,br_pxl:12,gradient:'engine_audio_display'},

			'2500': {l:20,w:50,t:25,h:10,br:0,deg:360,o:1,br_pxl:12,gradient:'engine_audio_display',content:'music_engine_song_1'},

			'2740': {l:20,w:50,t:25,h:10,br:0,deg:360,o:1,br_pxl:12,gradient:'engine_audio_display',content:'music_engine_song_1'},
			'2840': {l:20,w:50,t:5,h:10,br:0,deg:360,o:0,br_pxl:12,gradient:'engine_audio_display'},
		},
		'music_engine_song_2': {
			'0': {l:10,w:60,t:30,h:0,br:0,deg:0,o:0,br_pxl:12,gradient:'engine_audio_display'},

			'2100': {l:44,w:2,t:49,h:2,br:50,deg:360,o:0,br_pxl:12,gradient:'engine_audio_display'},
			'2300': {l:40,w:10,t:45,h:10,br:50,deg:360,o:1,br_pxl:12,gradient:'engine_audio_display'},

			'2500': {l:20,w:50,t:45,h:10,br:0,deg:360,o:1,br_pxl:12,gradient:'engine_audio_display',content:'music_engine_song_2'},

			'2800': {l:20,w:50,t:45,h:10,br:0,deg:360,o:1,br_pxl:12,gradient:'engine_audio_display',content:'music_engine_song_2'},
			'2900': {l:20,w:50,t:15,h:10,br:0,deg:360,o:0,br_pxl:12,gradient:'engine_audio_display'},
		},
		'music_engine_song_3': {
			'0': {l:10,w:60,t:30,h:0,br:0,deg:0,o:0,br_pxl:12,gradient:'engine_audio_display'},

			'2100': {l:44,w:2,t:49,h:2,br:50,deg:360,o:0,br_pxl:12,gradient:'engine_audio_display'},
			'2300': {l:40,w:10,t:45,h:10,br:50,deg:360,o:1,br_pxl:12,gradient:'engine_audio_display'},

			'2500': {l:20,w:50,t:65,h:10,br:0,deg:360,o:1,br_pxl:12,gradient:'engine_audio_display',content:'music_engine_song_3'},

			'2860': {l:20,w:50,t:65,h:10,br:0,deg:360,o:1,br_pxl:12,gradient:'engine_audio_display',content:'music_engine_song_3'},
			'2960': {l:20,w:50,t:15,h:10,br:0,deg:360,o:0,br_pxl:12,gradient:'engine_audio_display'},
		},


	// skill List
		'skill_1': {
			'0': {l:62,w:1,t:49,h:1,br:50,deg:0,o:0,lbl:'react_js',gradient:'dot_point'},
			'500': {l:62,w:1,t:49,h:1,br:50,deg:0,o:0,lbl:'react_js',gradient:'dot_point'},
			'540': {l:64,w:3,t:32,h:3,br:50,deg:0,o:1,lbl:'react_js',gradient:'dot_point'},

			'900': {l:64,w:3,t:32,h:3,br:50,deg:0,o:1,lbl:'react_js',gradient:'dot_point',content:'check'},
			'1060': {l:24,w:3,t:30,h:3,br:50,deg:0,o:1,lbl:'react_js',gradient:'dot_point',content:'check'},

			'1200': {l:24,w:3,t:30,h:3,br:50,deg:0,o:1,lbl:'react_js',gradient:'dot_point',content:'check'},

			'1300': {l:24,w:3,t:30,h:3,br:50,deg:0,o:1,lbl:'react_js',gradient:'dot_point',content:'check'},
			'1400': {l:24,w:3,t:20,h:3,br:50,deg:0,o:1,lbl:'react_js',gradient:'dot_point',content:'check'},

			'1700': {l:24,w:3,t:20,h:3,br:50,deg:0,o:1,lbl:'react_js',gradient:'dot_point',content:'check'},
			'2000': {l:24,w:3,t:-130,h:3,br:50,deg:0,o:0,lbl:'react_js',gradient:'dot_point',content:'check'},
		},
		'skill_2': {
			'0': {l:62,w:1,t:49,h:1,br:50,deg:0,o:0,lbl:'react_native',gradient:'dot_point'},
			'500': {l:62,w:1,t:49,h:1,br:50,deg:0,o:0,lbl:'react_native',gradient:'dot_point'},
			'540': {l:64,w:3,t:48,h:3,br:50,deg:0,o:1,lbl:'react_native',gradient:'dot_point'},

			'900': {l:64,w:3,t:48,h:3,br:50,deg:0,o:1,lbl:'react_native',gradient:'dot_point',content:'check'},
			'1060': {l:24,w:3,t:40,h:3,br:50,deg:0,o:1,lbl:'react_native',gradient:'dot_point',content:'check'},

			'1200': {l:24,w:3,t:40,h:3,br:50,deg:0,o:1,lbl:'react_native',gradient:'dot_point',content:'check'},

			'1300': {l:24,w:3,t:40,h:3,br:50,deg:0,o:1,lbl:'react_native',gradient:'dot_point',content:'check'},
			'1400': {l:24,w:3,t:30,h:3,br:50,deg:0,o:1,lbl:'react_native',gradient:'dot_point',content:'check'},

			'1700': {l:24,w:3,t:30,h:3,br:50,deg:0,o:1,lbl:'react_native',gradient:'dot_point',content:'check'},
			'2000': {l:24,w:3,t:-120,h:3,br:50,deg:0,o:0,lbl:'react_native',gradient:'dot_point',content:'check'},
		},
		'skill_3': {
			'0': {l:62,w:1,t:49,h:1,br:50,deg:0,o:0,lbl:'node',gradient:'dot_point'},
			'500': {l:62,w:1,t:49,h:1,br:50,deg:0,o:0,lbl:'node',gradient:'dot_point'},
			'540': {l:64,w:3,t:64,h:3,br:50,deg:0,o:1,lbl:'node',gradient:'dot_point'},

			'900': {l:64,w:3,t:64,h:3,br:50,deg:0,o:1,lbl:'node',gradient:'dot_point',content:'check'},
			'1060': {l:24,w:3,t:50,h:3,br:50,deg:0,o:1,lbl:'node',gradient:'dot_point',content:'check'},

			'1200': {l:24,w:3,t:50,h:3,br:50,deg:0,o:1,lbl:'node',gradient:'dot_point',content:'check'},

			'1300': {l:24,w:3,t:50,h:3,br:50,deg:0,o:1,lbl:'node',gradient:'dot_point',content:'check'},
			'1400': {l:24,w:3,t:40,h:3,br:50,deg:0,o:1,lbl:'node',gradient:'dot_point',content:'check'},

			'1700': {l:24,w:3,t:40,h:3,br:50,deg:0,o:1,lbl:'node',gradient:'dot_point',content:'check'},
			'2000': {l:24,w:3,t:-110,h:3,br:50,deg:0,o:0,lbl:'node',gradient:'dot_point',content:'check'},
		},
		'skill_4': {
			'0': {l:62,w:1,t:49,h:1,br:50,deg:0,o:0,lbl:'python',gradient:'dot_point'},
			'500': {l:62,w:1,t:49,h:1,br:50,deg:0,o:0,lbl:'python',gradient:'dot_point'},
			'540': {l:64,w:3,t:74,h:3,br:50,deg:0,o:0,lbl:'python',gradient:'dot_point'},

			'900': {l:64,w:3,t:74,h:3,br:50,deg:0,o:0,lbl:'python',gradient:'dot_point',content:'check'},
			'1060': {l:24,w:3,t:60,h:3,br:50,deg:0,o:1,lbl:'python',gradient:'dot_point',content:'check'},

			'1200': {l:24,w:3,t:60,h:3,br:50,deg:0,o:1,lbl:'python',gradient:'dot_point',content:'check'},

			'1300': {l:24,w:3,t:60,h:3,br:50,deg:0,o:1,lbl:'python',gradient:'dot_point',content:'check'},
			'1400': {l:24,w:3,t:50,h:3,br:50,deg:0,o:1,lbl:'python',gradient:'dot_point',content:'check'},

			'1700': {l:24,w:3,t:50,h:3,br:50,deg:0,o:1,lbl:'python',gradient:'dot_point',content:'check'},
			'2000': {l:24,w:3,t:-100,h:3,br:50,deg:0,o:0,lbl:'python',gradient:'dot_point',content:'check'},
		},
		'skill_5': {
			'0': {l:62,w:1,t:49,h:1,br:50,deg:0,o:0,lbl:'machine_learning',gradient:'dot_point'},
			'500': {l:62,w:1,t:49,h:1,br:50,deg:0,o:0,lbl:'machine_learning',gradient:'dot_point'},
			'540': {l:64,w:3,t:84,h:3,br:50,deg:0,o:0,lbl:'machine_learning',gradient:'dot_point'},

			'900': {l:64,w:3,t:84,h:3,br:50,deg:0,o:0,lbl:'machine_learning',gradient:'dot_point',content:'check'},
			'1060': {l:24,w:3,t:70,h:3,br:50,deg:0,o:1,lbl:'machine_learning',gradient:'dot_point',content:'check'},

			'1200': {l:24,w:3,t:70,h:3,br:50,deg:0,o:1,lbl:'machine_learning',gradient:'dot_point',content:'check'},

			'1300': {l:24,w:3,t:70,h:3,br:50,deg:0,o:1,lbl:'machine_learning',gradient:'dot_point',content:'check'},
			'1400': {l:24,w:3,t:60,h:3,br:50,deg:0,o:1,lbl:'machine_learning',gradient:'dot_point',content:'check'},

			'1700': {l:24,w:3,t:60,h:3,br:50,deg:0,o:1,lbl:'machine_learning',gradient:'dot_point',content:'check'},
			'2000': {l:24,w:3,t:-90,h:3,br:50,deg:0,o:0,lbl:'machine_learning',gradient:'dot_point',content:'check'},
		},

		'skill_6': {
			'0': {l:62,w:1,t:49,h:1,br:50,deg:0,o:0,lbl:'fast_learner',gradient:'dot_point',content:'check'},

			'1300': {l:24,w:3,t:80,h:3,br:50,deg:0,o:0,lbl:'fast_learner',gradient:'dot_point',content:'check'},
			'1400': {l:24,w:3,t:70,h:3,br:50,deg:0,o:1,lbl:'fast_learner',gradient:'dot_point',content:'check'},

			'1700': {l:24,w:3,t:70,h:3,br:50,deg:0,o:1,lbl:'fast_learner',gradient:'dot_point',content:'check'},
			'2000': {l:24,w:3,t:-80,h:3,br:50,deg:0,o:0,lbl:'fast_learner',gradient:'dot_point',content:'check'},
		},


	// MUSIC SECTION
		'music_box_1': {
			'0': {l:38,w:24,t:38,h:24,br:50,deg:0,o:0,content:'music_icon'},
			'3000': {l:48,w:4,t:48,h:4,br:50,deg:0,o:0,content:'music_icon'},
			'3080': {l:38,w:24,t:38,h:24,br:50,deg:0,o:1,content:'music_icon'},
			'3100': {l:38,w:24,t:38,h:24,br:50,deg:0,o:1,content:'music_icon'},

			'3200': {l:38,w:24,t:38,h:24,br:50,deg:0,o:1,content:'music_icon'},
			'3260': {l:42,w:16,t:42,h:16,br:50,deg:360,o:1,content:'album_art_about_you'},
			'3300': {l:34,w:32,t:42,h:16,br:2,deg:360,o:1,content:'album_art_about_you'},
			'3320': {l:34,w:32,t:20,h:62,br:2,deg:360,o:1,br_pxl:6,content:'album_art_about_you'},

			'3600': {l:34,w:32,t:20,h:62,br:2,deg:360,o:1,br_pxl:6,content:'album_art_about_you'},
			'3700': {l:0,w:27,t:26,h:50,br:2,deg:360,o:0,br_pxl:6,content:'album_art_about_you'},
		},
		'music_box_2': {
			'0': {l:64,w:27,t:20,h:54,br:2,deg:360,o:0,br_pxl:6,content:'album_art_drink_to_that'},

			'3600': {l:64,w:27,t:26,h:50,br:2,deg:360,o:0,br_pxl:6,content:'album_art_drink_to_that'},
			'3700': {l:34,w:32,t:20,h:62,br:2,deg:360,o:1,br_pxl:6,content:'album_art_drink_to_that'},

			'4000': {l:34,w:32,t:20,h:62,br:2,deg:360,o:1,br_pxl:6,content:'album_art_drink_to_that'},
			'4100': {l:0,w:27,t:26,h:50,br:2,deg:360,o:0,br_pxl:6,content:'album_art_drink_to_that'},
		},
		'music_box_3': {
			'0': {l:64,w:27,t:20,h:54,br:2,deg:360,o:0,br_pxl:6,content:'album_art_stay'},

			'4000': {l:64,w:27,t:26,h:50,br:2,deg:360,o:0,br_pxl:6,content:'album_art_stay'},
			'4100': {l:34,w:32,t:20,h:62,br:2,deg:360,o:1,br_pxl:6,content:'album_art_stay'},

			'4400': {l:34,w:32,t:20,h:62,br:2,deg:360,o:1,br_pxl:6,content:'album_art_stay'},
			'4500': {l:0,w:27,t:26,h:50,br:2,deg:360,o:0,br_pxl:6,content:'album_art_stay'},
		},
		'music_box_4': {
			'0': {l:64,w:27,t:20,h:54,br:2,deg:360,o:0,br_pxl:6,content:'album_art_flo'},

			'4400': {l:64,w:27,t:26,h:50,br:2,deg:360,o:0,br_pxl:6,content:'album_art_flo'},
			'4500': {l:34,w:32,t:20,h:62,br:2,deg:360,o:1,br_pxl:6,content:'album_art_flo'},

			'4700': {l:34,w:32,t:20,h:62,br:2,deg:360,o:1,br_pxl:6,content:'album_art_flo'},
			'4800': {l:0,w:27,t:26,h:50,br:2,deg:360,o:0,br_pxl:6,content:'album_art_flo'},
		},
		'music_box_5': {
			'0': {l:64,w:27,t:20,h:54,br:2,deg:360,o:0,br_pxl:6,content:'album_art_jc'},

			'4700': {l:64,w:27,t:26,h:50,br:2,deg:360,o:0,br_pxl:6,content:'album_art_jc'},
			'4800': {l:34,w:32,t:20,h:62,br:2,deg:360,o:0.5,br_pxl:6,content:'album_art_jc'},
			'4900': {l:34,w:32,t:0,h:22,br:2,deg:360,o:0,br_pxl:6,content:'album_art_jc'},

			// '5000': {l:34,w:32,t:20,h:62,br:2,deg:360,o:0,br_pxl:6,content:'album_art_jc'},
			// '5100': {l:0,w:27,t:26,h:50,br:2,deg:360,o:0,br_pxl:6,content:'album_art_jc'},
		},

		'music_box_1_shadow': {
			'0': {l:38,w:24,t:84,h:6,br:0,deg:0,o:0,shadow:true},
			'3000': {l:38,w:24,t:84,h:6,br:0,deg:0,o:0,shadow:true},
			'3080': {l:38,w:24,t:84,h:6,br:0,deg:0,o:0.3,shadow:true},
			'3100': {l:38,w:24,t:84,h:6,br:0,deg:0,o:0.3,shadow:true},

			'3200': {l:38,w:24,t:84,h:6,br:0,deg:0,o:0.3,shadow:true},
			'3260': {l:42,w:16,t:84,h:6,br:0,deg:0,o:0.7,shadow:true},
			'3300': {l:34,w:32,t:84,h:6,br:0,deg:0,o:0.7,shadow:true},
			'3320': {l:34,w:32,t:84,h:6,br:0,deg:0,o:0.7,shadow:true},

			'3600': {l:34,w:32,t:84,h:6,br:0,deg:0,o:0.7,shadow:true},
			'3700': {l:0,w:27,t:84,h:6,br:0,deg:0,o:0,shadow:true},
		},
		'music_box_2_shadow': {
			'0': {l:64,w:27,t:84,h:6,br:0,deg:360,o:0,shadow:true},

			'3600': {l:64,w:27,t:84,h:6,br:0,deg:360,o:0,shadow:true},
			'3700': {l:34,w:32,t:84,h:6,br:0,deg:360,o:0.7,shadow:true},

			'4000': {l:34,w:32,t:84,h:6,br:0,deg:360,o:0.7,shadow:true},
			'4100': {l:0,w:27,t:84,h:6,br:0,deg:360,o:0,shadow:true},
		},
		'music_box_3_shadow': {
			'0': {l:64,w:27,t:84,h:6,br:0,deg:360,o:0,shadow:true},

			'4000': {l:64,w:27,t:84,h:6,br:0,deg:360,o:0,shadow:true},
			'4100': {l:34,w:32,t:84,h:6,br:0,deg:360,o:0.7,shadow:true},

			'4400': {l:34,w:32,t:84,h:6,br:0,deg:360,o:0.7,shadow:true},
			'4500': {l:0,w:27,t:84,h:6,br:0,deg:360,o:0,shadow:true},
		},
		'music_box_4_shadow': {
			'0': {l:64,w:27,t:84,h:6,br:0,deg:360,o:0,shadow:true},

			'4400': {l:64,w:27,t:84,h:6,br:0,deg:360,o:0,shadow:true},
			'4500': {l:34,w:32,t:84,h:6,br:0,deg:360,o:0.7,shadow:true},

			'4700': {l:34,w:32,t:84,h:6,br:0,deg:360,o:0.7,shadow:true},
			'4800': {l:0,w:27,t:84,h:6,br:0,deg:360,o:0,shadow:true},
		},
		'music_box_5_shadow': {
			'0': {l:64,w:27,t:84,h:6,br:0,deg:360,o:0,shadow:true},

			'4700': {l:64,w:27,t:84,h:6,br:0,deg:360,o:0,shadow:true},
			'4800': {l:34,w:32,t:84,h:6,br:0,deg:360,o:0.7,shadow:true},
			'4900': {l:34,w:32,t:84,h:6,br:0,deg:360,o:0,shadow:true},

			'5000': {l:34,w:32,t:84,h:6,br:0,deg:360,o:0,shadow:true},
			'5100': {l:0,w:27,t:84,h:6,br:0,deg:360,o:0,shadow:true},
		},


		'music_text': {
			'0': {l:2,w:98,t:90,h:20,br:0,deg:0,o:0,text:'music_hero'},
			'3000': {l:2,w:98,t:90,h:40,br:0,deg:0,o:0,text:'music_hero'},
			'3050': {l:2,w:98,t:90,h:40,br:0,deg:0,o:0.7,text:'music_hero'},

			'4750': {l:2,w:98,t:90,h:40,br:0,deg:0,o:0.7,text:'music_hero'},
			'5000': {l:2,w:98,t:-24,h:40,br:0,deg:0,o:0,text:'music_hero'},

			// '5000': {l:2,w:98,t:44,h:40,br:0,deg:0,o:1,text:'music_hero'},
			// '5100': {l:2,w:98,t:-44,h:40,br:0,deg:0,o:0,text:'music_hero'},
			
		},


	// 3D renders
		'render_video': {
			'0': {l:0,w:100,t:100,h:100,br:0,deg:0,o:0,content:'rendering_video'},
			'4800': {l:0,w:100,t:100,h:100,br:0,deg:0,o:0,content:'rendering_video'},
			'5000': {l:0,w:100,t:0,h:100,br:0,deg:0,o:1,content:'rendering_video'},
		},
		'render_mobile_bkg': {
			'0': {l:0,w:100,t:100,h:100,br:0,deg:0,o:0,gradient:'ending_black_out'},
			'4800': {l:0,w:100,t:100,h:100,br:0,deg:0,o:0,gradient:'ending_black_out'},
			'5000': {l:0,w:100,t:0,h:100,br:0,deg:0,o:1,gradient:'ending_black_out'},
		},
		'render_mobile_1': {
			'0': {l:0,w:100,t:100,h:100,br:0,deg:0,o:0,content:'render_1'},
			'4800': {l:0,w:100,t:100,h:100,br:0,deg:0,o:0,content:'render_1'},
			'5000': {l:0,w:100,t:0,h:100,br:0,deg:0,o:1,content:'render_1'},
		},
		'render_mobile_2': {
			'0': {l:0,w:100,t:0,h:100,br:0,deg:0,o:0,content:'render_2'},
			'5300': {l:0,w:100,t:0,h:100,br:0,deg:0,o:0,content:'render_2'},
			'5400': {l:0,w:100,t:0,h:100,br:0,deg:0,o:1,content:'render_2'},
		},
		'render_mobile_3': {
			'0': {l:0,w:100,t:0,h:100,br:0,deg:0,o:0,content:'render_3'},
			'5600': {l:0,w:100,t:0,h:100,br:0,deg:0,o:0,content:'render_3'},
			'5700': {l:0,w:100,t:0,h:100,br:0,deg:0,o:1,content:'render_3'},
		},

	// finale
		// 'render_video': {
		// 	'0': {l:0,w:100,t:100,h:100,br:0,deg:0,o:0,content:'rendering_video'},
		// 	'4800': {l:0,w:100,t:100,h:100,br:0,deg:0,o:0,content:'rendering_video'},
		// 	'5000': {l:0,w:100,t:0,h:100,br:0,deg:0,o:1,content:'rendering_video'},
		// },

		'ending_profile_pic': {
			'0': {l:38,w:24,t:38,h:24,br:50,deg:0,o:0,content:'profile_pic'},
			'6000': {l:48,w:4,t:48,h:4,br:50,deg:0,o:0,content:'profile_pic'},
			'6080': {l:38,w:24,t:38,h:24,br:50,deg:0,o:1,content:'profile_pic'},
			'6100': {l:38,w:24,t:38,h:24,br:50,deg:0,o:1,content:'profile_pic'},

			'6200': {l:38,w:24,t:38,h:24,br:50,deg:0,o:1,content:'profile_pic'},
		},
		// 'music_text': {
		// 	'0': {l:2,w:98,t:90,h:20,br:0,deg:0,o:0,text:'music_hero'},
		// 	'3000': {l:2,w:98,t:90,h:40,br:0,deg:0,o:0,text:'music_hero'},
		// 	'3050': {l:2,w:98,t:90,h:40,br:0,deg:0,o:0.7,text:'music_hero'},

		// 	'4750': {l:2,w:98,t:90,h:40,br:0,deg:0,o:0.7,text:'music_hero'},
		// 	'5000': {l:2,w:98,t:-24,h:40,br:0,deg:0,o:0,text:'music_hero'},

		// 	// '5000': {l:2,w:98,t:44,h:40,br:0,deg:0,o:1,text:'music_hero'},
		// 	// '5100': {l:2,w:98,t:-44,h:40,br:0,deg:0,o:0,text:'music_hero'},
			
		// },
		'ending_text_1': {
			'0': {l:2,w:98,t:90,h:20,br:0,deg:0,o:0,text:'ending_hero'},
			'5980': {l:2,w:98,t:66,h:20,br:0,deg:0,o:0,text:'ending_hero'},
			'6030': {l:2,w:98,t:66,h:20,br:0,deg:0,o:0.7,text:'ending_hero'},

			'6750': {l:2,w:98,t:66,h:20,br:0,deg:0,o:0.7,text:'ending_hero'},
		},
		'ending_text_2': {
			'0': {l:2,w:98,t:90,h:20,br:0,deg:0,o:0,text:'ending_text2'},
			'5980': {l:2,w:98,t:74,h:20,br:0,deg:0,o:0,text:'ending_text2'},
			'6030': {l:2,w:98,t:74,h:20,br:0,deg:0,o:0.7,text:'ending_text2'},

			'6750': {l:2,w:98,t:74,h:20,br:0,deg:0,o:0.7,text:'ending_text2'},
		},
		'ending_text_3': {
			'0': {l:2,w:98,t:90,h:20,br:0,deg:0,o:0,text:'ending_text3'},
			'5980': {l:2,w:98,t:80,h:20,br:0,deg:0,o:0,text:'ending_text3'},
			'6030': {l:2,w:98,t:80,h:20,br:0,deg:0,o:0.7,text:'ending_text3'},

			'6750': {l:2,w:98,t:80,h:20,br:0,deg:0,o:0.7,text:'ending_text3'},
		},
}

const contents = {
	'profile_pic': {type:'img',src:liam_face_1,w:'110%'},

	'mobile_pic_1': {type:'img',src:tinder_ss_1,w:'100%'},
	'mobile_pic_2': {type:'img',src:tinder_ss_2,w:'100%'},

	'computer_pic_1': {type:'img',src:zillowhome,w:'110%'},


	'rendering_video': {type:'video',src:demo_vid_for_3drenders,h:'100%',ref:'video_1'},
	'lohom_vid': {type:'video',src:lohom_vid,h:'100%',ref:'video_2'},

	'check': {type:'img',src:check,w:'80%'},
	'music_icon': {type:'img',src:music_icon,w:'200%'},
	'album_art_about_you': {type:'img',src:album_art_about_you,w:'100%'},
	'album_art_drink_to_that': {type:'img',src:album_art_drink_to_that,w:'100%'},
	'album_art_stay': {type:'img',src:album_art_stay,w:'100%'},
	'album_art_flo': {type:'img',src:album_art_flo,w:'100%'},
	'album_art_jc': {type:'img',src:album_art_jc,w:'100%'},

	'code_image': {type:'img',src:code_image,w:'150%'},

	// 'bit_code_test_2': {type:'video',src:bit_code_test_2,h:'100%',ref:'video_3'},
	'computer_brain': {type:'img',src:computer_brain,w:'120%'},


	'songhatch_frontend_vid': {type:'video',src:songhatch_frontend_vid,h:'100%',ref:'video_4'},

	'music_engine_song_1': {type:'audio',src:song_example_1},
	'music_engine_song_2': {type:'audio',src:song_example_3},
	'music_engine_song_3': {type:'audio',src:song_example_4},


	'link_icon': {type:'img',src:link_icon,w:'60%'},
	'mobilemassage2': {'type':'img',src:mobilemassage2,w:'70%'},

	'render_1': {type:'img',src:bed2,w:'100%'},
	'render_2': {type:'img',src:cosey_bedroom_10,w:'100%'},
	'render_3': {type:'img',src:tina_house_render_1,w:'100%'},
}
const gradients = {
	'bgk_gradient1': {
		'0': {r1:50,g1:10,b1:80, r2:60,g2:80,b2:140},
		'480': {r1:50,g1:10,b1:80, r2:60,g2:80,b2:140,},
		'540': {r1:120,g1:110,b1:150, r2:60,g2:80,b2:180,},

		'900': {r1:120,g1:110,b1:150, r2:60,g2:80,b2:180,},
		'1000': {r1:120,g1:130,b1:180, r2:20,g2:40,b2:70,},
	},
	'dot_point': {
		'0': {r1:255,g1:255,b1:255, r2:255,g2:255,b2:255},
		'800': {r1:255,g1:255,b1:255, r2:255,g2:255,b2:255},
		'980': {r1:0,g1:255,b1:0, r2:0,g2:255,b2:0},
	},

	'music_background': {
		'0': {r1:0,g1:0,b1:20, r2:0,g2:0,b2:20},

		'3000': {r1:0,g1:0,b1:20, r2:0,g2:0,b2:20},
		'3120': {r1:34,g1:0,b1:20, r2:0,g2:0,b2:20},
	},
	'music_background_2': {
		'0': {r1:0,g1:0,b1:20, r2:0,g2:0,b2:20},

		'3000': {r1:0,g1:0,b1:20, r2:0,g2:0,b2:20},
		'3120': {r1:74,g1:50,b1:60, r2:60,g2:70,b2:100},
	},


	'dark_code': {
		'0': {r1:10,g1:0,b1:40, r2:10,g2:0,b2:0},
		'2200': {r1:10,g1:0,b1:40, r2:10,g2:0,b2:0},
		'2400': {r1:10,g1:0,b1:40, r2:70,g2:60,b2:80},
	},
	'ending_black_out': {
		'0': {r1:20,g1:15,b1:15, r2:20,g2:15,b2:15},
		// '2200': {r1:10,g1:0,b1:40, r2:10,g2:0,b2:0},
		// '2400': {r1:10,g1:0,b1:40, r2:70,g2:60,b2:80},
	},

	'songhatch_frontend': {
		'0': {r1:124,g1:159,b1:237, r2:166,g2:220,b2:235},
	},
	// 'songhatch_frontend_input': {
	// 	'0': {r1:193,g1:220,b1:244, r2:193,g2:220,b2:244},
	// },

	'white_background': {
		'0': {r1:255,g1:255,b1:255, r2:255,g2:255,b2:255},
	},
	'engine_audio_display': {
		// '0': {r1:62,g1:51,b1:75, r2:72,g2:61,b2:85},
		'0': {r1:215,g1:215,b1:215, r2:225,g2:225,b2:225},
	},

	link_gradient: {
		'0': {r1:50,g1:55,b1:125, r2:60,g2:125,b2:145},
	},
	song_hatch_bkg: {
		'0': {r1:181,g1:163,b1:217, r2:181,g2:163,b2:217},
	},
}
const labels = {
	'hero_text': {
		'0': {text:['Developer'],className:'text_hero bold uppercase'},

		'100': {text:['Developer'],className:'text_hero bold uppercase'},
		'120': {text:['Develope'],className:'text_hero bold uppercase'},
		'140': {text:['Develop'],className:'text_hero bold uppercase'},
		'160': {text:['Develo'],className:'text_hero bold uppercase'},
		'180': {text:['Devel'],className:'text_hero bold uppercase'},
		'200': {text:['Deve'],className:'text_hero bold uppercase'},
		'220': {text:['Dev'],className:'text_hero bold uppercase'},
		'240': {text:['De'],className:'text_hero bold uppercase'},
		'260': {text:['D'],className:'text_hero bold uppercase'},
		'280': {text:[''],className:'text_hero bold uppercase'},
	},
	'sub_hero_text': {
		'0': {text:['Passionate about technology and applying it creatively'],className:'text_sub_hero boldx uppercase'},
		
		'100': {text:['Passionate about technology and applying it crea'],className:'text_sub_hero boldx uppercase'},
		'120': {text:['Passionate about technology and applying it'],className:'text_sub_hero boldx uppercase'},
		'140': {text:['Passionate about technology and applyi'],className:'text_sub_hero boldx uppercase'},
		'160': {text:['Passionate about technology and ap'],className:'text_sub_hero boldx uppercase'},
		'180': {text:['Passionate about technology a'],className:'text_sub_hero boldx uppercase'},
		'200': {text:['Passionate about technol'],className:'text_sub_hero boldx uppercase'},
		'220': {text:['Passionate about tec'],className:'text_sub_hero boldx uppercase'},
		'240': {text:['Passionate abou'],className:'text_sub_hero boldx uppercase'},
		'260': {text:['Passionate'],className:'text_sub_hero boldx uppercase'},
		'280': {text:['Passi'],className:'text_sub_hero boldx uppercase'},
		'300': {text:[''],className:'text_sub_hero boldx uppercase'},
	},
	'hero_text_mobile': {
		'0': {text:['Developer'],className:'text_hero bold uppercase textAlignCenter'},

		'100': {text:['Developer'],className:'text_hero bold uppercase textAlignCenter'},
		'120': {text:['Develope'],className:'text_hero bold uppercase textAlignCenter'},
		'140': {text:['Develop'],className:'text_hero bold uppercase textAlignCenter'},
		'160': {text:['Develo'],className:'text_hero bold uppercase textAlignCenter'},
		'180': {text:['Devel'],className:'text_hero bold uppercase textAlignCenter'},
		'200': {text:['Deve'],className:'text_hero bold uppercase textAlignCenter'},
		'220': {text:['Dev'],className:'text_hero bold uppercase textAlignCenter'},
		'240': {text:['De'],className:'text_hero bold uppercase textAlignCenter'},
		'260': {text:['D'],className:'text_hero bold uppercase textAlignCenter'},
		'280': {text:[''],className:'text_hero bold uppercase textAlignCenter'},
	},
	'sub_hero_text_mobile': {
		'0': {text:['Passionate about technology and applying it creatively'],className:'text_sub_hero boldx uppercase textAlignCenter'},
		
		'100': {text:['Passionate about technology and applying it crea'],className:'text_sub_hero boldx uppercase textAlignCenter'},
		'120': {text:['Passionate about technology and applying it'],className:'text_sub_hero boldx uppercase textAlignCenter'},
		'140': {text:['Passionate about technology and applyi'],className:'text_sub_hero boldx uppercase textAlignCenter'},
		'160': {text:['Passionate about technology and ap'],className:'text_sub_hero boldx uppercase textAlignCenter'},
		'180': {text:['Passionate about technology a'],className:'text_sub_hero boldx uppercase textAlignCenter'},
		'200': {text:['Passionate about technol'],className:'text_sub_hero boldx uppercase textAlignCenter'},
		'220': {text:['Passionate about tec'],className:'text_sub_hero boldx uppercase textAlignCenter'},
		'240': {text:['Passionate abou'],className:'text_sub_hero boldx uppercase textAlignCenter'},
		'260': {text:['Passionate'],className:'text_sub_hero boldx uppercase textAlignCenter'},
		'280': {text:['Passi'],className:'text_sub_hero boldx uppercase textAlignCenter'},
		'300': {text:[''],className:'text_sub_hero boldx uppercase textAlignCenter'},
	},

	'react_js': {
		'0': {text:[''],className:'text_largest bold'},
		'500': {text:['R'],className:'text_largest bold'},
		'520': {text:['Re'],className:'text_largest bold'},
		'540': {text:['Rea'],className:'text_largest bold'},
		'560': {text:['Reac'],className:'text_largest bold'},
		'580': {text:['React'],className:'text_largest bold'},
	},
	'react_native': {
		'0': {text:[''],className:'text_largest bold'},
		'500': {text:['R'],className:'text_largest bold'},
		'520': {text:['Re'],className:'text_largest bold'},
		'540': {text:['Rea'],className:'text_largest bold'},
		'560': {text:['Reac'],className:'text_largest bold'},
		'580': {text:['React'],className:'text_largest bold'},
		'600': {text:['React N'],className:'text_largest bold'},
		'620': {text:['React Na'],className:'text_largest bold'},
		'640': {text:['React Nat'],className:'text_largest bold'},
		'660': {text:['React Nati'],className:'text_largest bold'},
		'680': {text:['React Nativ'],className:'text_largest bold'},
		'700': {text:['React Native'],className:'text_largest bold'},
	},
	'node': {
		'0': {text:[''],className:'text_largest bold'},
		'500': {text:['N'],className:'text_largest bold'},
		'520': {text:['No'],className:'text_largest bold'},
		'540': {text:['Nod'],className:'text_largest bold'},
		'560': {text:['Node'],className:'text_largest bold'},
	},
	'python': {
		'0': {text:[''],className:'text_largest bold'},
		'900': {text:['P'],className:'text_largest bold'},
		'920': {text:['Py'],className:'text_largest bold'},
		'940': {text:['Pyt'],className:'text_largest bold'},
		'960': {text:['Pyth'],className:'text_largest bold'},
		'980': {text:['Pytho'],className:'text_largest bold'},
		'1000': {text:['Python'],className:'text_largest bold'},
	},
	'machine_learning': {
		'0': {text:[''],className:'text_largest bold'},
		'900': {text:['M'],className:'text_largest bold'},
		'920': {text:['Ma'],className:'text_largest bold'},
		'940': {text:['Mac'],className:'text_largest bold'},
		'960': {text:['Mach'],className:'text_largest bold'},
		'980': {text:['Machi'],className:'text_largest bold'},
		'1000': {text:['Machin'],className:'text_largest bold'},
		'1020': {text:['Machine'],className:'text_largest bold'},
		'1040': {text:['Machine L'],className:'text_largest bold'},
		'1060': {text:['Machine Le'],className:'text_largest bold'},
		'1080': {text:['Machine Lea'],className:'text_largest bold'},
		'1100': {text:['Machine Lear'],className:'text_largest bold'},
		'1120': {text:['Machine Learn'],className:'text_largest bold'},
		'1140': {text:['Machine Learni'],className:'text_largest bold'},
		'1160': {text:['Machine Learnin'],className:'text_largest bold'},
		'1180': {text:['Machine Learning'],className:'text_largest bold'},
	},
	fast_learner: {
		'0': {text:[''],className:'text_largest bold'},
		'1120': {text:['V'],className:'text_largest bold'},
		'1140': {text:['Ve'],className:'text_largest bold'},
		'1160': {text:['Ver'],className:'text_largest bold'},
		'1180': {text:['Very'],className:'text_largest bold'},
		'1200': {text:['Very F'],className:'text_largest bold'},
		'1220': {text:['Very Fa'],className:'text_largest bold'},
		'1240': {text:['Very Fas'],className:'text_largest bold'},
		'1260': {text:['Very Fast'],className:'text_largest bold'},
		'1280': {text:['Very Fast L'],className:'text_largest bold'},
		'1300': {text:['Very Fast Le'],className:'text_largest bold'},
		'1320': {text:['Very Fast Lea'],className:'text_largest bold'},
		'1340': {text:['Very Fast Lear'],className:'text_largest bold'},
		'1360': {text:['Very Fast Learn'],className:'text_largest bold'},
		'1380': {text:['Very Fast Learne'],className:'text_largest bold'},
		'1400': {text:['Very Fast Learner'],className:'text_largest bold'},
	},

	'skills_text': {
		'0': {text:[''],className:'text_hero bold uppercase flexLeft'},

		'400': {text:['S'],className:'text_hero bold uppercase flexLeft'},
		'440': {text:['SK'],className:'text_hero bold uppercase flexLeft'},
		'480': {text:['SKI'],className:'text_hero bold uppercase flexLeft'},
		'520': {text:['SKIL'],className:'text_hero bold uppercase flexLeft'},
		'560': {text:['SKILL'],className:'text_hero bold uppercase flexLeft'},
		'600': {text:['SKILLS'],className:'text_hero bold uppercase flexLeft'},

		'1600': {text:['SKILL'],className:'text_hero bold uppercase'},
		'1620': {text:['SKIL'],className:'text_hero bold uppercase'},
		'1640': {text:['SKI'],className:'text_hero bold uppercase'},
		'1660': {text:['SK'],className:'text_hero bold uppercase'},
		'1680': {text:['S'],className:'text_hero bold uppercase'},
		'1700': {text:[''],className:'text_hero bold uppercase'},

	},


	'music_hero': {
		'0': {text:'',className:'text_sub_hero bold uppercase textAlignCenter'},

		'3000': {text:[''],className:'text_sub_hero bold uppercase textAlignCenter'},
		'3020': {text:['1'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'3040': {text:['10'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'3060': {text:['100'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'3080': {text:['100 M'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'3100': {text:['100 MI'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'3120': {text:['100 MIL'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'3140': {text:['100 MILL'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'3160': {text:['100 MILLI'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'3180': {text:['100 MILLIO'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'3200': {text:['100 MILLION'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'3220': {text:['100 MILLION S'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'3240': {text:['100 MILLION ST'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'3260': {text:['100 MILLION STR'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'3280': {text:['100 MILLION STRE'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'3300': {text:['100 MILLION STREA'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'3320': {text:['100 MILLION STREAM'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'3340': {text:['100 MILLION STREAMS'],className:'text_sub_hero bold uppercase textAlignCenter'},
	},

	generation_engine_heading: {
		'0': {text:[''],className:'text_hero bold uppercase'},

		'1300': {text:['M'],className:'text_hero bold uppercase flexLeft'},
		'1320': {text:['Mu'],className:'text_hero bold uppercase flexLeft'},
		'1340': {text:['Mus'],className:'text_hero bold uppercase flexLeft'},
		'1360': {text:['Musi'],className:'text_hero bold uppercase flexLeft'},
		'1380': {text:['Music'],className:'text_hero bold uppercase flexLeft'},
		'1400': {text:['Music g'],className:'text_hero bold uppercase flexLeft'},
		'1420': {text:['Music ge'],className:'text_hero bold uppercase flexLeft'},
		'1440': {text:['Music gen'],className:'text_hero bold uppercase flexLeft'},
		'1460': {text:['Music gene'],className:'text_hero bold uppercase flexLeft'},
		'1480': {text:['Music gener'],className:'text_hero bold uppercase flexLeft'},
		'1500': {text:['Music genera'],className:'text_hero bold uppercase flexLeft'},
		'1520': {text:['Music generat'],className:'text_hero bold uppercase flexLeft'},
		'1540': {text:['Music generati'],className:'text_hero bold uppercase flexLeft'},
		'1560': {text:['Music generatio'],className:'text_hero bold uppercase flexLeft'},
		'1580': {text:['Music generation'],className:'text_hero bold uppercase flexLeft'},
		'1600': {text:['Music generation e'],className:'text_hero bold uppercase flexLeft'},
		'1620': {text:['Music generation en'],className:'text_hero bold uppercase flexLeft'},
		'1640': {text:['Music generation eng'],className:'text_hero bold uppercase flexLeft'},
		'1660': {text:['Music generation engi'],className:'text_hero bold uppercase flexLeft'},
		'1680': {text:['Music generation engin'],className:'text_hero bold uppercase flexLeft'},
		'1700': {text:['Music generation engine'],className:'text_hero bold uppercase flexLeft'},
	},
	production_heading: {
		'0': {text:[''],className:'text_hero bold uppercase'},
		'3060': {text:['M'],className:'text_hero bold uppercase'},
		'3080': {text:['Mu'],className:'text_hero bold uppercase'},
		'3100': {text:['Mus'],className:'text_hero bold uppercase'},
		'3120': {text:['Musi'],className:'text_hero bold uppercase'},
		'3140': {text:['Music'],className:'text_hero bold uppercase'},
		'3160': {text:['Music p'],className:'text_hero bold uppercase'},
		'3180': {text:['Music pr'],className:'text_hero bold uppercase'},
		'3200': {text:['Music pro'],className:'text_hero bold uppercase'},
		'3220': {text:['Music prod'],className:'text_hero bold uppercase'},
		'3240': {text:['Music produ'],className:'text_hero bold uppercase'},
		'3260': {text:['Music produc'],className:'text_hero bold uppercase'},
		'3280': {text:['Music product'],className:'text_hero bold uppercase'},
		'3300': {text:['Music producti'],className:'text_hero bold uppercase'},
		'3320': {text:['Music productio'],className:'text_hero bold uppercase'},
		'3340': {text:['Music production'],className:'text_hero bold uppercase'},
	},


	threeDrender_heading: {
		'0': {text:[''],className:'text_hero bold uppercase flexLeft'},

		'5000': {text:['3'],className:'text_hero bold uppercase flexLeft'},
		'5020': {text:['3D'],className:'text_hero bold uppercase flexLeft'},
		'5040': {text:['3D r'],className:'text_hero bold uppercase flexLeft'},
		'5060': {text:['3D re'],className:'text_hero bold uppercase flexLeft'},
		'5080': {text:['3D ren'],className:'text_hero bold uppercase flexLeft'},
		'5100': {text:['3D rend'],className:'text_hero bold uppercase flexLeft'},
		'5120': {text:['3D rende'],className:'text_hero bold uppercase flexLeft'},
		'5140': {text:['3D render'],className:'text_hero bold uppercase flexLeft'},
		'5160': {text:['3D renderi'],className:'text_hero bold uppercase flexLeft'},
		'5180': {text:['3D renderin'],className:'text_hero bold uppercase flexLeft'},
		'5200': {text:['3D rendering'],className:'text_hero bold uppercase flexLeft'},
	},


	'music_engine_paragraph': {
		'0': {
			text:['I built a Generative Music engine, that takes a text prompt and returns a high quality peice of audio that matches the given prompt. It feels and appears as though it is A.I, but the music generation process uses zero A.I, meaning that all music generated is 100% commercially useable. There are no datasets so there is never any concern of infringing on copywritten material.',
				'Have a listen to the generated audio here to get an idea of the quality this engine can produce.',
			],
			className:'text_larger'
		},
	},

	'engine_4': {
		'0': {text:[''],className:'text_largest bold'},
		'2060': {text:['H'],className:'text_largest bold'},
		'2080': {text:['Hi'],className:'text_largest bold'},
		'2100': {text:['Hig'],className:'text_largest bold'},
		'2120': {text:['High'],className:'text_largest bold'},
		'2140': {text:['High q'],className:'text_largest bold'},
		'2160': {text:['High qu'],className:'text_largest bold'},
		'2180': {text:['High qua'],className:'text_largest bold'},
		'2200': {text:['High qual'],className:'text_largest bold'},
		'2220': {text:['High quali'],className:'text_largest bold'},
		'2240': {text:['High qualit'],className:'text_largest bold'},
		'2260': {text:['High quality'],className:'text_largest bold'},
		'2280': {text:['High quality a'],className:'text_largest bold'},
		'2300': {text:['High quality au'],className:'text_largest bold'},
		'2320': {text:['High quality aud'],className:'text_largest bold'},
		'2340': {text:['High quality audi'],className:'text_largest bold'},
		'2360': {text:['High quality audio'],className:'text_largest bold'},
		'2380': {text:['High quality audio o'],className:'text_largest bold'},
		'2400': {text:['High quality audio ou'],className:'text_largest bold'},
		'2420': {text:['High quality audio out'],className:'text_largest bold'},
		'2440': {text:['High quality audio outp'],className:'text_largest bold'},
		'2460': {text:['High quality audio outpu'],className:'text_largest bold'},
		'2480': {text:['High quality audio output'],className:'text_largest bold'},

	},
	'engine_1': {
		'0': {text:[''],className:'text_largest bold'},
		'2060': {text:['T'],className:'text_largest bold'},
		'2080': {text:['Te'],className:'text_largest bold'},
		'2100': {text:['Tex'],className:'text_largest bold'},
		'2120': {text:['Text'],className:'text_largest bold'},
		'2140': {text:['Text p'],className:'text_largest bold'},
		'2160': {text:['Text pr'],className:'text_largest bold'},
		'2180': {text:['Text pro'],className:'text_largest bold'},
		'2200': {text:['Text prom'],className:'text_largest bold'},
		'2220': {text:['Text promp'],className:'text_largest bold'},
		'2240': {text:['Text prompt'],className:'text_largest bold'},
		'2260': {text:['Text prompts'],className:'text_largest bold'},
	},
	'engine_2': {
		'0': {text:[''],className:'text_largest bold'},
		'2060': {text:['Z'],className:'text_largest bold'},
		'2080': {text:['Ze'],className:'text_largest bold'},
		'2100': {text:['Zer'],className:'text_largest bold'},
		'2120': {text:['Zero'],className:'text_largest bold'},
		'2140': {text:['Zero A'],className:'text_largest bold'},
		'2160': {text:['Zero A.'],className:'text_largest bold'},
		'2180': {text:['Zero A.I'],className:'text_largest bold'},
		'2200': {text:['Zero A.I.'],className:'text_largest bold'},
		'2220': {text:['Zero A.I. u'],className:'text_largest bold'},
		'2240': {text:['Zero A.I. us'],className:'text_largest bold'},
		'2260': {text:['Zero A.I. use'],className:'text_largest bold'},
		'2280': {text:['Zero A.I. used'],className:'text_largest bold'},
	},
	'engine_3': {
		'0': {text:[''],className:'text_largest bold'},
		'2060': {text:['C'],className:'text_largest bold'},
		'2080': {text:['Co'],className:'text_largest bold'},
		'2100': {text:['Com'],className:'text_largest bold'},
		'2120': {text:['Comm'],className:'text_largest bold'},
		'2140': {text:['Comme'],className:'text_largest bold'},
		'2160': {text:['Commer'],className:'text_largest bold'},
		'2180': {text:['Commerc'],className:'text_largest bold'},
		'2200': {text:['Commerci'],className:'text_largest bold'},
		'2220': {text:['Commercia'],className:'text_largest bold'},
		'2240': {text:['Commercial'],className:'text_largest bold'},
		'2260': {text:['Commerciall'],className:'text_largest bold'},
		'2280': {text:['Commercially'],className:'text_largest bold'},
		'2300': {text:['Commercially u'],className:'text_largest bold'},
		'2320': {text:['Commercially us'],className:'text_largest bold'},
		'2340': {text:['Commercially use'],className:'text_largest bold'},
		'2360': {text:['Commercially usea'],className:'text_largest bold'},
		'2380': {text:['Commercially useab'],className:'text_largest bold'},
		'2400': {text:['Commercially useabl'],className:'text_largest bold'},
		'2420': {text:['Commercially useable'],className:'text_largest bold'},
	},
	'engine_5': {
		'0': {text:[''],className:'text_largest bold'},
		'2060': {text:['N'],className:'text_largest bold'},
		'2080': {text:['No'],className:'text_largest bold'},
		'2100': {text:['No c'],className:'text_largest bold'},
		'2120': {text:['No co'],className:'text_largest bold'},
		'2140': {text:['No cop'],className:'text_largest bold'},
		'2160': {text:['No copy'],className:'text_largest bold'},
		'2180': {text:['No copyr'],className:'text_largest bold'},
		'2200': {text:['No copyri'],className:'text_largest bold'},
		'2220': {text:['No copyrig'],className:'text_largest bold'},
		'2240': {text:['No copyrigh'],className:'text_largest bold'},
		'2260': {text:['No copyright'],className:'text_largest bold'},
		'2280': {text:['No copyright c'],className:'text_largest bold'},
		'2300': {text:['No copyright co'],className:'text_largest bold'},
		'2320': {text:['No copyright con'],className:'text_largest bold'},
		'2340': {text:['No copyright conc'],className:'text_largest bold'},
		'2360': {text:['No copyright conce'],className:'text_largest bold'},
		'2380': {text:['No copyright concer'],className:'text_largest bold'},
		'2400': {text:['No copyright concern'],className:'text_largest bold'},
		'2420': {text:['No copyright concerns'],className:'text_largest bold'},
	},




	portfolio_link: {
		'0': {text:[''],className:'text_largest bold underline pointer'},

		'600': {text:['V'],className:'text_largest bold underline pointer'},
		'620': {text:['Vi'],className:'text_largest bold underline pointer'},
		'640': {text:['Vie'],className:'text_largest bold underline pointer'},
		'660': {text:['View'],className:'text_largest bold underline pointer'},
		'680': {text:['View p'],className:'text_largest bold underline pointer'},
		'700': {text:['View po'],className:'text_largest bold underline pointer'},
		'720': {text:['View por'],className:'text_largest bold underline pointer'},
		'740': {text:['View portf'],className:'text_largest bold underline pointer'},
		'760': {text:['View portfo'],className:'text_largest bold underline pointer'},
		'780': {text:['View portfol'],className:'text_largest bold underline pointer'},
		'800': {text:['View portfoli'],className:'text_largest bold underline pointer'},
		'820': {text:['View portfolio'],className:'text_largest bold underline pointer'},

		'1440': {text:['View portfolio'],className:'text_largest bold underline'},
		'1460': {text:['View portfoli'],className:'text_largest bold underline'},
		'1480': {text:['View portfol'],className:'text_largest bold underline'},
		'1500': {text:['View portfo'],className:'text_largest bold underline'},
		'1520': {text:['View portf'],className:'text_largest bold underline'},
		'1540': {text:['View por'],className:'text_largest bold underline'},
		'1560': {text:['View po'],className:'text_largest bold underline'},
		'1580': {text:['View p'],className:'text_largest bold underline'},
		'1600': {text:['View'],className:'text_largest bold underline'},
		'1620': {text:['Vie'],className:'text_largest bold underline'},
		'1640': {text:['Vi'],className:'text_largest bold underline'},
		'1660': {text:['V'],className:'text_largest bold underline'},
		'1680': {text:[''],className:'text_largest bold underline'},
	},
	engine_link: {
		'0': {text:[''],className:'text_largest bold underline pointer'},

		'2100': {text:['A'],className:'text_largest bold underline pointer'},
		'2120': {text:['Au'],className:'text_largest bold underline pointer'},
		'2140': {text:['Aud'],className:'text_largest bold underline pointer'},
		'2160': {text:['Audi'],className:'text_largest bold underline pointer'},
		'2180': {text:['Audio'],className:'text_largest bold underline pointer'},
		'2200': {text:['Audio e'],className:'text_largest bold underline pointer'},
		'2220': {text:['Audio ex'],className:'text_largest bold underline pointer'},
		'2240': {text:['Audio exa'],className:'text_largest bold underline pointer'},
		'2260': {text:['Audio exam'],className:'text_largest bold underline pointer'},
		'2280': {text:['Audio examp'],className:'text_largest bold underline pointer'},
		'2300': {text:['Audio exampl'],className:'text_largest bold underline pointer'},
		'2320': {text:['Audio example'],className:'text_largest bold underline pointer'},
		'2340': {text:['Audio examples'],className:'text_largest bold underline pointer'},

		'2620': {text:['Audio example'],className:'text_largest bold underline pointer'},
		'2640': {text:['Audio exampl'],className:'text_largest bold underline pointer'},
		'2660': {text:['Audio examp'],className:'text_largest bold underline pointer'},
		'2680': {text:['Audio exam'],className:'text_largest bold underline pointer'},
		'2700': {text:['Audio exa'],className:'text_largest bold underline pointer'},
		'2720': {text:['Audio ex'],className:'text_largest bold underline pointer'},
		'2740': {text:['Audio e'],className:'text_largest bold underline pointer'},
		'2760': {text:['Audio'],className:'text_largest bold underline pointer'},
		'2780': {text:['Audi'],className:'text_largest bold underline pointer'},
		'2800': {text:['Aud'],className:'text_largest bold underline pointer'},
		'2820': {text:['Au'],className:'text_largest bold underline pointer'},
		'2840': {text:['A'],className:'text_largest bold underline pointer'},
		'2860': {text:[''],className:'text_largest bold underline pointer'},
	},
	production_link: {
		'0': {text:[''],className:'text_largest bold underline pointer'},

		'3000': {text:['P'],className:'text_largest bold underline pointer'},
		'3020': {text:['Pr'],className:'text_largest bold underline pointer'},
		'3040': {text:['Pro'],className:'text_largest bold underline pointer'},
		'3060': {text:['Prod'],className:'text_largest bold underline pointer'},
		'3080': {text:['Produ'],className:'text_largest bold underline pointer'},
		'3100': {text:['Produc'],className:'text_largest bold underline pointer'},
		'3120': {text:['Product'],className:'text_largest bold underline pointer'},
		'3140': {text:['Producti'],className:'text_largest bold underline pointer'},
		'3160': {text:['Productio'],className:'text_largest bold underline pointer'},
		'3180': {text:['Production'],className:'text_largest bold underline pointer'},
		'3200': {text:['Production d'],className:'text_largest bold underline pointer'},
		'3220': {text:['Production di'],className:'text_largest bold underline pointer'},
		'3240': {text:['Production dis'],className:'text_largest bold underline pointer'},
		'3260': {text:['Production disc'],className:'text_largest bold underline pointer'},
		'3280': {text:['Production disco'],className:'text_largest bold underline pointer'},
		'3300': {text:['Production discog'],className:'text_largest bold underline pointer'},
		'3320': {text:['Production discogr'],className:'text_largest bold underline pointer'},
		'3340': {text:['Production discogra'],className:'text_largest bold underline pointer'},
		'3360': {text:['Production discograp'],className:'text_largest bold underline pointer'},
		'3380': {text:['Production discograph'],className:'text_largest bold underline pointer'},
		'3400': {text:['Production discography'],className:'text_largest bold underline pointer'},

		'4300': {text:['Production discography'],className:'text_largest bold underline pointer'},
		'4320': {text:['Production discograph'],className:'text_largest bold underline pointer'},
		'4340': {text:['Production discograp'],className:'text_largest bold underline pointer'},
		'4360': {text:['Production discogra'],className:'text_largest bold underline pointer'},
		'4380': {text:['Production discogr'],className:'text_largest bold underline pointer'},
		'4400': {text:['Production discog'],className:'text_largest bold underline pointer'},
		'4420': {text:['Production disco'],className:'text_largest bold underline pointer'},
		'4440': {text:['Production disc'],className:'text_largest bold underline pointer'},
		'4460': {text:['Production dis'],className:'text_largest bold underline pointer'},
		'4480': {text:['Production di'],className:'text_largest bold underline pointer'},
		'4500': {text:['Production d'],className:'text_largest bold underline pointer'},
		'4520': {text:['Production'],className:'text_largest bold underline pointer'},
		'4540': {text:['Productio'],className:'text_largest bold underline pointer'},
		'4560': {text:['Producti'],className:'text_largest bold underline pointer'},
		'4580': {text:['Product'],className:'text_largest bold underline pointer'},
		'4600': {text:['Produc'],className:'text_largest bold underline pointer'},
		'4620': {text:['Produ'],className:'text_largest bold underline pointer'},
		'4640': {text:['Prod'],className:'text_largest bold underline pointer'},
		'4660': {text:['Pro'],className:'text_largest bold underline pointer'},
		'4680': {text:['Pr'],className:'text_largest bold underline pointer'},
		'4700': {text:['P'],className:'text_largest bold underline pointer'},
		'4720': {text:[''],className:'text_largest bold underline pointer'},
	},
	rednering_link: {
		'0': {text:[''],className:'text_largest bold underline pointer'},

		'4980': {text:['R'],className:'text_largest bold underline pointer'},
		'5000': {text:['Re'],className:'text_largest bold underline pointer'},
		'5020': {text:['Ren'],className:'text_largest bold underline pointer'},
		'5040': {text:['Rend'],className:'text_largest bold underline pointer'},
		'5060': {text:['Rende'],className:'text_largest bold underline pointer'},
		'5080': {text:['Render'],className:'text_largest bold underline pointer'},
		'5100': {text:['Renderi'],className:'text_largest bold underline pointer'},
		'5120': {text:['Renderin'],className:'text_largest bold underline pointer'},
		'5140': {text:['Rendering'],className:'text_largest bold underline pointer'},
		'5160': {text:['Rendering p'],className:'text_largest bold underline pointer'},
		'5180': {text:['Rendering po'],className:'text_largest bold underline pointer'},
		'5200': {text:['Rendering por'],className:'text_largest bold underline pointer'},
		'5220': {text:['Rendering port'],className:'text_largest bold underline pointer'},
		'5240': {text:['Rendering portf'],className:'text_largest bold underline pointer'},
		'5260': {text:['Rendering portfo'],className:'text_largest bold underline pointer'},
		'5280': {text:['Rendering portfol'],className:'text_largest bold underline pointer'},
		'5300': {text:['Rendering portfoli'],className:'text_largest bold underline pointer'},
		'5320': {text:['Rendering portfolio'],className:'text_largest bold underline pointer'},


	},

	'ending_hero': {
		'0': {text:'',className:'text_sub_hero bold uppercase textAlignCenter'},

		'6000': {text:[''],className:'text_sub_hero bold uppercase textAlignCenter'},
		'6020': {text:['L'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'6040': {text:['Li'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'6060': {text:['Lia'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'6080': {text:['Liam'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'6100': {text:['Liam F'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'6120': {text:['Liam Fu'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'6140': {text:['Liam Fud'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'6160': {text:['Liam Fudg'],className:'text_sub_hero bold uppercase textAlignCenter'},
		'6180': {text:['Liam Fudge'],className:'text_sub_hero bold uppercase textAlignCenter'},
	},
	'ending_text2': {
		'0': {text:'',className:'text_largest white_color textAlignCenter'},

		'6000': {text:[''],className:'text_largest white_color textAlignCenter'},
		'6020': {text:['l'],className:'text_largest white_color textAlignCenter'},
		'6040': {text:['li'],className:'text_largest white_color textAlignCenter'},
		'6060': {text:['lia'],className:'text_largest white_color textAlignCenter'},
		'6080': {text:['liam'],className:'text_largest white_color textAlignCenter'},
		'6100': {text:['liam.'],className:'text_largest white_color textAlignCenter'},
		'6120': {text:['liam.f'],className:'text_largest white_color textAlignCenter'},
		'6140': {text:['liam.fu'],className:'text_largest white_color textAlignCenter'},
		'6160': {text:['liam.fud'],className:'text_largest white_color textAlignCenter'},
		'6180': {text:['liam.fudg'],className:'text_largest white_color textAlignCenter'},
		
		'6200': {text:['liam.fudge'],className:'text_largest white_color textAlignCenter'},
		'6220': {text:['liam.fudge@'],className:'text_largest white_color textAlignCenter'},
		'6240': {text:['liam.fudge@g'],className:'text_largest white_color textAlignCenter'},
		'6260': {text:['liam.fudge@gm'],className:'text_largest white_color textAlignCenter'},
		'6280': {text:['liam.fudge@gma'],className:'text_largest white_color textAlignCenter'},
		'6300': {text:['liam.fudge@gmai'],className:'text_largest white_color textAlignCenter'},
		'6320': {text:['liam.fudge@gmail'],className:'text_largest white_color textAlignCenter'},
		'6340': {text:['liam.fudge@gmail.'],className:'text_largest white_color textAlignCenter'},
		'6360': {text:['liam.fudge@gmail.c'],className:'text_largest white_color textAlignCenter'},
		'6380': {text:['liam.fudge@gmail.co'],className:'text_largest white_color textAlignCenter'},
		'6400': {text:['liam.fudge@gmail.com'],className:'text_largest white_color textAlignCenter'},

	},

	'ending_text3': {
		'0': {text:'',className:'text_largest white_color textAlignCenter'},

		'6100': {text:[''],className:'text_largest white_color textAlignCenter'},
		'6120': {text:['('],className:'text_largest white_color textAlignCenter'},
		'6140': {text:['(4'],className:'text_largest white_color textAlignCenter'},
		'6160': {text:['(44'],className:'text_largest white_color textAlignCenter'},
		'6180': {text:['(443'],className:'text_largest white_color textAlignCenter'},
		'6200': {text:['(443)'],className:'text_largest white_color textAlignCenter'},
		'6220': {text:['(443) 7'],className:'text_largest white_color textAlignCenter'},
		'6240': {text:['(443) 71'],className:'text_largest white_color textAlignCenter'},
		'6260': {text:['(443) 713'],className:'text_largest white_color textAlignCenter'},
		'6280': {text:['(443) 713-'],className:'text_largest white_color textAlignCenter'},
		'6300': {text:['(443) 713-9'],className:'text_largest white_color textAlignCenter'},
		'6320': {text:['(443) 713-97'],className:'text_largest white_color textAlignCenter'},
		'6340': {text:['(443) 713-972'],className:'text_largest white_color textAlignCenter'},
		'6360': {text:['(443) 713-9723'],className:'text_largest white_color textAlignCenter'},
	},
}

const HomeRoute = ({dimensions,scroll,setModal}) => {

	const vid_ref_1 = useRef()
	const vid_ref_2 = useRef()
	const vid_ref_3 = useRef()
	const vid_ref_4 = useRef()
	useEffect(()=>{
		if(vid_ref_1.current !== undefined){
			if(scroll >= 5000) vid_ref_1.current.currentTime = ((scroll-5000)/200);
		}
		if(vid_ref_2.current !== undefined){
			if(scroll >= 980) vid_ref_2.current.currentTime = ((scroll-980)/150);
		}
		if(vid_ref_3.current !== undefined){
			if(scroll >= 80) vid_ref_3.current.currentTime = ((scroll-80)/300);
		}
		if(vid_ref_4.current !== undefined){
			if(scroll >= 980) vid_ref_4.current.currentTime = ((scroll-980)/100);
		}
	},[scroll,vid_ref_1]);

	function handle_ref(e,contents) {
		if(contents.ref === 'video_1'){
			vid_ref_1.current = e
		} else if(contents.ref === 'video_2'){
			vid_ref_2.current = e
		} else if(contents.ref === 'video_3'){
			vid_ref_3.current = e
		} else if(contents.ref === 'video_4'){
			vid_ref_4.current = e
		}
	}

	function element_clicked(e) {
		if(e.link !== undefined){
			setModal(e.link);
		}
	}



	function get_gradient_colors(input) {
		let bottom_number = 0
		let top_number = 0
		for(const [key, ] of Object.entries(input)){
			if(scroll >= Number(key)){
				bottom_number = Number(key);
			} else if(top_number === 0){
				top_number = Number(key);
				break
			}
		}
		if(bottom_number !== 0 && top_number === 0){
			top_number = bottom_number
		}
		const r11 = input[String(bottom_number)]['r1'];
		const g11 = input[String(bottom_number)]['g1'];
		const b11 = input[String(bottom_number)]['b1'];

		const r12 = input[String(top_number)]['r1'];
		const g12 = input[String(top_number)]['g1'];
		const b12 = input[String(top_number)]['b1'];


		const r21 = input[String(bottom_number)]['r2'];
		const g21 = input[String(bottom_number)]['g2'];
		const b21 = input[String(bottom_number)]['b2'];

		const r22 = input[String(top_number)]['r2'];
		const g22 = input[String(top_number)]['g2'];
		const b22 = input[String(top_number)]['b2'];

		const scroll_diff = (scroll-bottom_number)/(top_number-bottom_number)
		let r1 = (r12-r11)*scroll_diff + r11;
		let g1 = (g12-g11)*scroll_diff + g11;
		let b1 = (b12-b11)*scroll_diff + b11;

		let r2 = (r22-r21)*scroll_diff + r21;
		let g2 = (g22-g21)*scroll_diff + g21;
		let b2 = (b22-b21)*scroll_diff + b21;

		if(top_number === bottom_number){
			r1 = r11;
			g1 = g11;
			b1 = b11;

			r2 = r21;
			g2 = g21;
			b2 = b21;
		}

		return {r1:r1, g1:g1, b1:b1, r2:r2, g2:g2, b2:b2}
	}

	function get_text_display(input) {
		let bottom_number = 0
		for(const [key, ] of Object.entries(input)){
			if(scroll >= Number(key)){
				bottom_number = Number(key);
			} else {
				break
			}
		}
		const text = input[String(bottom_number)]['text'];
		const className = input[String(bottom_number)]['className'];
		return {text:text,className:className}
	}

	function coordinateShape(component) {
		let bottom_number = 0
		let top_number = 0
		for(const [key] of Object.entries(scroll_object_data[component])){
			if(scroll >= Number(key)){
				bottom_number = Number(key);
			} else if(top_number === 0){
				top_number = Number(key);
				break
			}
		}
		if(bottom_number !== 0 && top_number === 0){
			top_number = bottom_number
		}
		const l1 = scroll_object_data[component][String(bottom_number)]['l'];  // 40
		const w1 = scroll_object_data[component][String(bottom_number)]['w'];  // 50
		const t1 = scroll_object_data[component][String(bottom_number)]['t'];  // 400
		const h1 = scroll_object_data[component][String(bottom_number)]['h'];  // 410
		const br1 = scroll_object_data[component][String(bottom_number)]['br'];
		const deg1 = scroll_object_data[component][String(bottom_number)]['deg'];
		const o1 = scroll_object_data[component][String(bottom_number)]['o'];

		const l2 = scroll_object_data[component][String(top_number)]['l'];  // 40
		const w2 = scroll_object_data[component][String(top_number)]['w'];  // 180
		const t2 = scroll_object_data[component][String(top_number)]['t'];  // 400
		const h2 = scroll_object_data[component][String(top_number)]['h'];  // 440
		const br2 = scroll_object_data[component][String(top_number)]['br'];
		const deg2 = scroll_object_data[component][String(top_number)]['deg'];
		const o2 = scroll_object_data[component][String(top_number)]['o'];

		const scroll_diff = (scroll-bottom_number)/(top_number-bottom_number)
		let l = (l2-l1)*scroll_diff + l1 
		let w = (w2-w1)*scroll_diff + w1
		let t = (t2-t1)*scroll_diff + t1  
		let h = (h2-h1)*scroll_diff + h1
		let br = (br2-br1)*scroll_diff + br1
		let deg = (deg2-deg1)*scroll_diff + deg1
		let o = (o2-o1)*scroll_diff + o1
		if(top_number === bottom_number){
			l = l1 
			w = w1
			t = t1  
			h = h1
			br = br1
			deg = deg1
			o = o1
		}
		br = br+'%';

		let content = ''
		if(scroll_object_data[component][String(bottom_number)]['content'] !== undefined) content = scroll_object_data[component][String(bottom_number)]['content']
		let contnt = ''
		if(contents[content] !== undefined){
			if(contents[content].type === 'img'){
				contnt = <img src={contents[content].src} alt='variable_img' style={{width:contents[content].w}} />
			}
			if(contents[content].type === 'video'){
				contnt = <video  height={contents[content].h} id='videoplayer' ref={(e)=>{handle_ref(e,contents[content])}}>
						<source src={contents[content].src} type='video/mp4' />
					</video>
			}
			if(contents[content].type === 'audio'){
				contnt = <audio src={contents[content].src} controls volume={1}></audio>
			}
		}
		let gradient = ''
		if(scroll_object_data[component][String(bottom_number)]['gradient'] !== undefined) gradient = scroll_object_data[component][String(bottom_number)]['gradient']
		let grdnt = '';
		if(gradients[gradient] !== undefined){
			const cols = get_gradient_colors(gradients[gradient])
			grdnt = <div style={{height:'100%',width:'100%',backgroundImage:`linear-gradient(to right,rgb(${cols.r1},${cols.g1},${cols.b1}),rgb(${cols.r2},${cols.g2},${cols.b2}))`}}></div>
		}
		let label = ''
		if(scroll_object_data[component][String(bottom_number)]['lbl'] !== undefined) label = scroll_object_data[component][String(bottom_number)]['lbl']
		let labl = ''
		if(labels[label] !== undefined){
			const text = get_text_display(labels[label])
			let arr = []
			for(let i = 0; i < text.text.length; i++){
				arr.push(
					<div key={i} style={{}}>{text.text[i]}</div>
				)
			}
			labl = <div className={'flexHeightCenter absolute ' + text.className} style={{height:'100%',width:800,marginLeft:40}}>{arr}</div>
		}
		let txt = ''
		let text_input = ''
		if(scroll_object_data[component][String(bottom_number)]['text'] !== undefined) text_input = scroll_object_data[component][String(bottom_number)]['text']
		if(labels[text_input] !== undefined){
			const text = get_text_display(labels[text_input])
			let arr = []
			for(let i = 0; i < text.text.length; i++){
				arr.push(
					<div key={i} style={{marginBottom:20}}>{text.text[i]}</div>
				)
			}
			txt = <div className={text.className} style={{height:'100%',width:'100%',border:'0px solid red'}}>{arr}</div>
		}
		// let br_pxl = 0
		if(scroll_object_data[component][String(bottom_number)]['br_pxl'] !== undefined){
			// br_pxl = scroll_object_data[component][String(bottom_number)]['br_pxl']
			br = scroll_object_data[component][String(bottom_number)]['br_pxl']
		}
		let shadow = '';
		if(scroll_object_data[component][String(bottom_number)]['shadow'] !== undefined){
			shadow = <div style={{height:'100%',width:'100%',
					backgroundImage:'radial-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.15),rgba(0,0,0,0),rgba(0,0,0,0))'
					// background:'red',
					}}></div>
		}
		return(
			<div style={{pointerEvents:'auto'}} onClick={()=>{element_clicked(scroll_object_data[component][String(bottom_number)])}}>
				<div style={{left:l+'%',top:t+'%',height:h+'%',width:w+'%',
						borderRadius:br,
						transform:`rotate(${deg}deg)`,opacity:o,
						overflow:'hidden',
						border:'0px solid red'}} className='absolute flexCenter boxShadowx'>
					<div className='absolute flexCenter' style={{height:'100%',width:'100%'}}>{grdnt}</div>
					<div className='absolute flexCenter' style={{height:'100%',width:'100%',pointerEvents:'auto'}}>{contnt}</div>
					<div className='absolute flexCenterColumn' style={{height:'100%',width:'100%',border:'0px solid red'}}>{txt}</div>
					<div className='absolute' style={{width:'100%',height:'100%'}}>{shadow}</div>
				</div>
				<div style={{left:l+'%',top:t+'%',height:h+'%',width:w+'%',opacity:o,border:'0px solid red'}} className='absolute'>{labl}</div>
			</div>
		)
	}
	let widest_point = dimensions.width-80;
	if(dimensions.height < dimensions.width) widest_point = dimensions.height-80;
	if(dimensions.width < 700){
		widest_point = dimensions.width;
		if(dimensions.height < dimensions.width) widest_point = dimensions.height;
		return(
			<div className='pointerNone white_color'>
			<div style={{height:dimensions.height+200,width:dimensions.width,top:0,border:'0px solid red'}} className='fixed flexCenter'>
				{coordinateShape('landing_clr_block')}
				{coordinateShape('code_block')}
				{coordinateShape('music_clr_block')}
				{coordinateShape('render_mobile_bkg')}
				{coordinateShape('render_mobile_1')}
				{coordinateShape('render_mobile_2')}
				{coordinateShape('render_mobile_3')}
				{coordinateShape('ending_clr_block')}
				
			</div>
			<div className='fixed textShadow' style={{height:dimensions.height-10,width:dimensions.width,top:10,zIndex:20}}>
				{coordinateShape('skills_heading')}
				{coordinateShape('generation_engine_heading')}
				{coordinateShape('production_heading')}
				{coordinateShape('threeDrender_heading')}
			</div>
			<div className='flexCenter fixed' style={{height:dimensions.height,width:dimensions.width,top:0,border:'0px solid red'}}>
				<div style={{width:widest_point*1.5,height:widest_point*1.5,border:'0px solid green',marginLeft:- (widest_point*1.5-dimensions.width)/2,marginRight: -(widest_point*1.5-dimensions.width)/2 }} className='relative'>
					{coordinateShape('music_box_1')}
					{coordinateShape('music_box_2')}
					{coordinateShape('music_box_3')}
					{coordinateShape('music_box_4')}
					{coordinateShape('music_box_5')}

					{coordinateShape('music_box_1_shadow')}
					{coordinateShape('music_box_2_shadow')}
					{coordinateShape('music_box_3_shadow')}
					{coordinateShape('music_box_4_shadow')}
					{coordinateShape('music_box_5_shadow')}

					{coordinateShape('music_text')}

					{coordinateShape('ending_profile_pic')}
					{coordinateShape('ending_text_1')}
					{coordinateShape('ending_text_2')}
					{coordinateShape('ending_text_3')}
				</div>
			</div>
			<div style={{height:dimensions.height-60,width:dimensions.width,top:80,border:'0px solid red'}} className='fixed flexCenterColumn'>
				<div style={{width:dimensions.width*0.9,height:dimensions.width*0.9,border:'0px solid pink'}} className='relative'>
					{coordinateShape('phone_box')}
					{coordinateShape('phone_shadow')}

					{coordinateShape('hero_text_mobile')}
					{coordinateShape('sub_hero_text_mobile')}

					{coordinateShape('skill_1')}
					{coordinateShape('skill_2')}
					{coordinateShape('skill_3')}
					{coordinateShape('skill_4')}
					{coordinateShape('skill_5')}
					{coordinateShape('skill_6')}

					{/*{coordinateShape('engine_1')}
					{coordinateShape('engine_2')}
					{coordinateShape('engine_3')}
					{coordinateShape('engine_4')}
					{coordinateShape('engine_5')}*/}
				</div>
			</div>
			<div style={{height:dimensions.height-60,width:dimensions.width-100,top:140,border:'0px solid red'}} className='fixed flexCenterColumn'>
				<div style={{width:dimensions.width,height:dimensions.width*0.8,border:'0px solid pink'}} className='relative'>
					{/*{coordinateShape('phone_box')}
					{coordinateShape('phone_shadow')}

					{coordinateShape('hero_text_mobile')}
					{coordinateShape('sub_hero_text_mobile')}

					{coordinateShape('skill_1')}
					{coordinateShape('skill_2')}
					{coordinateShape('skill_3')}
					{coordinateShape('skill_4')}
					{coordinateShape('skill_5')}
					{coordinateShape('skill_6')}*/}

					{coordinateShape('engine_1')}
					{coordinateShape('engine_2')}
					{coordinateShape('engine_3')}
					{coordinateShape('engine_4')}
					{coordinateShape('engine_5')}
				</div>
			</div>


			<div style={{height:dimensions.height,width:dimensions.width,top:0,border:'0px solid red'}} className='fixed flexHeightBottom'>
				<div style={{width:dimensions.width*0.2,height:dimensions.width}} className='relative'>
					</div>
				<div style={{width:dimensions.width*0.8,height:dimensions.width*0.8,border:'0px solid yellow'}} className='relative'>
					{coordinateShape('songhatch_backend_mobile')}

					{coordinateShape('songhatch_frontend_mobile')}
					{coordinateShape('songhatch_frontend_shadow')}

					{/*{coordinateShape('music_engine_song_1')}*/}
					{/*{coordinateShape('music_engine_song_2')}*/}
					{/*{coordinateShape('music_engine_song_3')}*/}
				</div>
			</div>

			<div className='fixed flexCenter' style={{bottom:0,left:0,width:80,height:80,zIndex:20}}>
				{coordinateShape('portfolio_link')}
				{coordinateShape('engine_link')}
				{coordinateShape('production_link')}
				{coordinateShape('rednering_link')}
			</div>

			</div>
		)
	}
	return(
		<div className='pointerNone white_color'>
		<div style={{height:dimensions.height,width:dimensions.width,top:0,border:'0px solid red'}} className='fixed flexCenter textShadowx'>
			{coordinateShape('landing_clr_block')}
			{coordinateShape('code_block')}
			{coordinateShape('music_clr_block')}
			{coordinateShape('skills_heading')}
			{coordinateShape('generation_engine_heading')}
			{coordinateShape('production_heading')}
			{coordinateShape('render_video')}
			{coordinateShape('threeDrender_heading')}
			{coordinateShape('ending_clr_block')}
		</div>
		<div className='flexCenter fixed' style={{height:dimensions.height,width:dimensions.width,top:0,border:'0px solid red'}}>
			<div style={{width:widest_point,height:widest_point,border:'0px solid green'}} className='relative'>
				{coordinateShape('music_box_1')}
				{coordinateShape('music_box_2')}
				{coordinateShape('music_box_3')}
				{coordinateShape('music_box_4')}
				{coordinateShape('music_box_5')}

				{coordinateShape('music_box_1_shadow')}
				{coordinateShape('music_box_2_shadow')}
				{coordinateShape('music_box_3_shadow')}
				{coordinateShape('music_box_4_shadow')}
				{coordinateShape('music_box_5_shadow')}

				{coordinateShape('music_text')}
				{coordinateShape('ending_profile_pic')}
				{coordinateShape('ending_text_1')}
				{coordinateShape('ending_text_2')}
				{coordinateShape('ending_text_3')}
			</div>
		</div>
		<div style={{height:dimensions.height,width:dimensions.width,top:0,border:'0px solid red'}} className='fixed flexCenter'>
			<div style={{width:dimensions.width/2,height:dimensions.width/2,border:'0px solid pink'}} className='relative'>
				{coordinateShape('phone_box')}
				{coordinateShape('phone_shadow')}

				{coordinateShape('skill_1')}
				{coordinateShape('skill_2')}
				{coordinateShape('skill_3')}
				{coordinateShape('skill_4')}
				{coordinateShape('skill_5')}
				{coordinateShape('skill_6')}
				{coordinateShape('hero_text')}
				{coordinateShape('sub_hero_text')}


				{coordinateShape('engine_1')}
				{coordinateShape('engine_2')}
				{coordinateShape('engine_3')}
				{coordinateShape('engine_4')}
				{coordinateShape('engine_5')}

				{/*{coordinateShape('music_engine_paragraph')}*/}
			</div>
			<div style={{width:dimensions.width/2,height:dimensions.width/2,border:'0px solid red'}} className='relative'></div>
		</div>

		<div style={{height:dimensions.height,width:dimensions.width,top:0,border:'0px solid red'}} className='fixed flexCenter'>
			<div style={{width:dimensions.width*0.4,height:dimensions.width}} className='relative'>
				</div>
			<div style={{width:dimensions.width*0.6,height:dimensions.width*0.6,border:'0px solid yellow'}} className='relative'>
				{coordinateShape('songhatch_backend')}
				{coordinateShape('songhatch_backend_shadow')}

				{coordinateShape('songhatch_frontend')}
				{coordinateShape('songhatch_frontend_shadow')}

				{/*{coordinateShape('music_engine_song_1')}*/}
				{/*{coordinateShape('music_engine_song_2')}*/}
				{/*{coordinateShape('music_engine_song_3')}*/}
			</div>
		</div>

		<div className='fixed flexCenter' style={{bottom:20,left:20,width:80,height:80}}>
			{coordinateShape('portfolio_link')}
			{coordinateShape('engine_link')}
			{coordinateShape('production_link')}
			{coordinateShape('rednering_link')}
		</div>

		</div>
	)
}

export default HomeRoute;