import React, { useState, useEffect, useRef } from 'react';

import ChatContainer from '../Chat/ChatContainer';

import liam_face_1 from '../../Images/liam_fudge_512.png';
import message_icon from '../../Images/message_icon.svg';
import mail from '../../Images/mail.svg';
import Chevron from '../../Images/chevron-left.svg';
import home from '../../Images/home.svg';
import music from '../../Images/music.svg';
import code from '../../Images/code.svg';
import speaker from '../../Images/speaker.svg';
import award from '../../Images/award.svg';
import download from '../../Images/download-cloud.svg';

import Resume from '../../Images/Liam-Fudge-Resume-2021.pdf';

const NavBar = ({dimensions,setModal}) => {
	const [ dropdown, setDropdown ] = useState(false);
	const [ contact, setContact ] = useState(false);
	const [ chat, setChat ] = useState(true)

	// // Handle dropdown
	// 	const node = useRef();
	// 	useEffect(()=>{
	// 		function handleClick(e) {
	// 			if(node.current) {
	// 				if(!node.current.contains(e.target)){
	// 					setDropdown(false);
	// 					setContact(false)
	// 				}
	// 			}
	// 		}
	// 		document.addEventListener('mousedown', handleClick, false);
	// 		return () => {
	// 			document.removeEventListener('mousedown', handleClick, false);
	// 		}
	// 	},[]);

	function openDropdown() { setDropdown(true) }


	function dropdownDisplay(chat_width,chat_height) {
		return <DropdownDisplayComponent contact={contact} setContact={setContact} chat_width={chat_width} chat_height={chat_height}
					setModal={setModal} chat={chat} setChat={setChat} setDropdown={setDropdown} />
			// if(contact === true){
			// 	return(
			// 		<div className='absolute boxShadow white_background borderRadius'
			// 				style={{
			// 					// marginTop:chat_height+140,marginRight:20
			// 					marginLeft:-chat_width+50,width:chat_width,marginTop:10
			// 				}} ref={node}>
			// 			<div className='bk_main flexCenter white_color' style={{height:40}}>
			// 				<div style={{width:'30%'}}>
			// 					<div onClick={()=>{setContact(false)}} className='pointer flexCenter' style={{height:'100%',width:70}}>
			// 						<img src={Chevron} alt='Chevron' style={{height:26}} />
			// 						Back
			// 					</div>
			// 				</div>
			// 				<div style={{width:'40%'}} className='flexCenter bold'>{chat === true ? 'Chat' : 'Message' }</div>
			// 				<div style={{width:'30%'}}></div>
			// 			</div>
					
			// 			<ChatContainer height={chat_height} width={chat_width-26} chat={chat} setChat={setChat} />
			// 		</div>
			// 	)
			// }
			// return(
			// 	<div className='absolute boxShadow white_background borderRadius text_main'
			// 			style={{
			// 				// marginTop:250+80,
			// 				marginLeft:-230,width:280,marginTop:10
			// 			}}
			// 			ref={node}>

			// 		<div>
			// 			<div className='text_main dropdownItemx' style={{background:'rgb(52,105,206)'}}>

			// 				<div className='flexHeightCenter white_color bold' style={{height:40}}>
			// 					<img src={message_icon} alt='message_icon' style={{height:20,marginRight:6,marginTop:2,marginLeft:12}} />
			// 					Chat
			// 				</div>
			// 				<div className='dropdownItem borderBottom' style={{background:'rgb(220,230,250)',width:244,margin:'auto',borderRadius:4,border:'1px solid rgba(0,0,0,0.4)',cursor:'default'}}>
								
			// 					<div>Have a conversation and ask my A.I self about my story, my skills, or anything you like...</div>
			// 					<div className='flexLeft' style={{marginTop:14,marginBottom:10}}> 
			// 						<div className='button flexCenter bk_main white_color borderRadius pointer hoverOpacityDown' onClick={()=>{setContact(true);setChat(true)}}>
			// 							<img src={message_icon} alt='message_icon' style={{height:20,marginRight:6}} />
			// 							Enter chat
			// 						</div>
			// 					</div>

			// 					<div className='flexCenter halfOpacity' style={{}}>- Or -</div>
			// 					<div className='flexLeft' style={{marginTop:10}}> 
			// 						<div className='button flexCenter bk_main white_color borderRadius pointer hoverOpacityDown' onClick={()=>{setContact(true);setChat(false)}}>
			// 							<img src={mail} alt='mail' style={{height:20,marginRight:6}} />
			// 							Send message
			// 						</div>
			// 					</div>
			// 				</div>
			// 				<div style={{height:4}}></div>
			// 			</div>
							
			// 		</div>

			// 		<div className='dropdownItem borderBottom flexHeightCenter' onClick={()=>{setModal('development');setDropdown(false)}}>
			// 			<img src={code} alt='code' style={{height:14,marginRight:8,marginTop:1}} />
			// 			Developement Portfolio
			// 		</div>
			// 		<div className='dropdownItem borderBottom flexHeightCenter' onClick={()=>{setModal('engine');setDropdown(false)}}>
			// 			<img src={speaker} alt='speaker' style={{height:14,marginRight:8,marginTop:1}} />
			// 			Music Generation Engine
			// 		</div>
			// 		<div className='dropdownItem borderBottom flexHeightCenter' onClick={()=>{setModal('production');setDropdown(false)}}>
			// 			<img src={music} alt='code' style={{height:14,marginRight:8,marginTop:1}} />
			// 			Production Discography
			// 		</div>
			// 		<div className='dropdownItem borderBottom flexHeightCenter' onClick={()=>{setModal('rendering');setDropdown(false)}}>
			// 			<img src={home} alt='code' style={{height:14,marginRight:8,marginTop:1}} />
			// 			3D Rendering
			// 		</div>
			// 		<div className='dropdownItem borderBottom flexHeightCenter' onClick={()=>{setModal('certificates');setDropdown(false)}}>
			// 			<img src={award} alt='code' style={{height:14,marginRight:8,marginTop:1}} />
			// 			Certificates
			// 		</div>
			// 		<a href={Resume} download className='dropdownItem borderBottom flexHeightCenter'
			// 				style={{
			// 					// marginRight:20,width:160,
			// 					textDecoration:'none',color:'black'}}
			// 				>
			// 			<img src={download} alt='download' style={{height:14,marginRight:8,marginTop:1}} />
			// 			Download CV
			// 		</a>
			// 	</div>
			// )
	}

	let chat_width = 400
	if(dimensions.width < 460){
		chat_width = dimensions.width - 60;
	}
	let chat_height = 500
	if(dimensions.height < 640){
		chat_height = dimensions.height - 140;
	}
	return(
		<div style={{zIndex:20,position:'fixed',top:0,left:0,right:0,height:80}} className='flexHeightCenter flexRight'>
			<div style={{marginRight:14}} className='white_color'>
				<div className='text_main bold flexRight textShadow'>Liam Fudge</div>
				<div className='text_smaller halfOpacity bold textShadow' style={{marginTop:4}}>Developer, producer</div>
			</div>
			<div>
				<div style={{marginRight:20,height:48,width:48,borderRadius:50,overflow:'hidden',border:'1px solid rgb(220,220,220)'}}
						className='boxShadow pointer flexCenter' onClick={openDropdown}>
					<img src={liam_face_1} alt='liam_face_1' style={{width:'130%',marginLeft:-4,marginTop:6}} />
				</div>
				{dropdown === true
					? <div>{dropdownDisplay(chat_width,chat_height)}</div>
					: null
				}
			</div>
		</div>
	)
}

export default NavBar;


const DropdownDisplayComponent = ({contact,setContact,chat_width,chat_height,setModal,chat,setChat,setDropdown}) => {

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return ()=> document.body.style.overflow = 'unset';
	}, []);

	// Handle dropdown
		const node = useRef();
		useEffect(()=>{
			function handleClick(e) {
				if(node.current) {
					if(!node.current.contains(e.target)){
						setDropdown(false);
						setContact(false)
					}
				}
			}
			document.addEventListener('mousedown', handleClick, false);
			return () => {
				document.removeEventListener('mousedown', handleClick, false);
			}
		},[setDropdown,setContact]);



	if(contact === true){
		return(
			<div className='absolute boxShadow white_background borderRadius'
					style={{
						// marginTop:chat_height+140,marginRight:20
						marginLeft:-chat_width+50,width:chat_width,marginTop:10
					}} ref={node}>
				<div className='bk_main flexCenter white_color' style={{height:40}}>
					<div style={{width:'30%'}}>
						<div onClick={()=>{setContact(false)}} className='pointer flexCenter' style={{height:'100%',width:70}}>
							<img src={Chevron} alt='Chevron' style={{height:26}} />
							Back
						</div>
					</div>
					<div style={{width:'40%'}} className='flexCenter bold'>{chat === true ? 'Chat' : 'Message' }</div>
					<div style={{width:'30%'}}></div>
				</div>
			
				<ChatContainer height={chat_height} width={chat_width-26} chat={chat} setChat={setChat} />
			</div>
		)
	}

	return(
		<div className='absolute boxShadow white_background borderRadius text_main'
				style={{
					// marginTop:250+80,
					marginLeft:-230,width:280,marginTop:10
				}}
				ref={node}>

			<div>
				<div className='text_main dropdownItemx' style={{background:'rgb(52,105,206)'}}>

					<div className='flexHeightCenter white_color bold' style={{height:40}}>
						<img src={message_icon} alt='message_icon' style={{height:20,marginRight:6,marginTop:2,marginLeft:12}} />
						Chat
					</div>
					<div className='dropdownItem borderBottom' style={{background:'rgb(220,230,250)',width:244,margin:'auto',borderRadius:4,border:'1px solid rgba(0,0,0,0.4)',cursor:'default'}}>
						
						<div>Have a conversation and ask my A.I self about my story, my skills, or anything you like...</div>
						<div className='flexLeft' style={{marginTop:14,marginBottom:10}}> 
							<div className='button flexCenter bk_main white_color borderRadius pointer hoverOpacityDown' onClick={()=>{setContact(true);setChat(true)}}>
								<img src={message_icon} alt='message_icon' style={{height:20,marginRight:6}} />
								Enter chat
							</div>
						</div>

						<div className='flexCenter halfOpacity' style={{}}>- Or -</div>
						<div className='flexLeft' style={{marginTop:10}}> 
							<div className='button flexCenter bk_main white_color borderRadius pointer hoverOpacityDown' onClick={()=>{setContact(true);setChat(false)}}>
								<img src={mail} alt='mail' style={{height:20,marginRight:6}} />
								Send message
							</div>
						</div>
					</div>
					<div style={{height:4}}></div>
				</div>
					
			</div>

			<div className='dropdownItem borderBottom flexHeightCenter' onClick={()=>{setModal('development');setDropdown(false)}}>
				<img src={code} alt='code' style={{height:14,marginRight:8,marginTop:1}} />
				Developement Portfolio
			</div>
			<div className='dropdownItem borderBottom flexHeightCenter' onClick={()=>{setModal('engine');setDropdown(false)}}>
				<img src={speaker} alt='speaker' style={{height:14,marginRight:8,marginTop:1}} />
				Music Generation Engine
			</div>
			<div className='dropdownItem borderBottom flexHeightCenter' onClick={()=>{setModal('production');setDropdown(false)}}>
				<img src={music} alt='code' style={{height:14,marginRight:8,marginTop:1}} />
				Production Discography
			</div>
			<div className='dropdownItem borderBottom flexHeightCenter' onClick={()=>{setModal('rendering');setDropdown(false)}}>
				<img src={home} alt='code' style={{height:14,marginRight:8,marginTop:1}} />
				3D Rendering
			</div>
			<div className='dropdownItem borderBottom flexHeightCenter' onClick={()=>{setModal('certificates');setDropdown(false)}}>
				<img src={award} alt='code' style={{height:14,marginRight:8,marginTop:1}} />
				Certificates
			</div>
			<a href={Resume} download className='dropdownItem borderBottom flexHeightCenter'
					style={{
						// marginRight:20,width:160,
						textDecoration:'none',color:'black'}}
					>
				<img src={download} alt='download' style={{height:14,marginRight:8,marginTop:1}} />
				Download CV
			</a>
		</div>
	)

}









