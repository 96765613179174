import ChatGPTElement from './ChatGPTElement';
import SendMessage from './SendMessage';

const ChatContainer = ({height,width,chat,setChat}) =>{

	return(
		<div style={{padding:10}} className='boxShadow border'>
			<div>
				<div className='flexCenter option_selector black_colorx'>
					{chat === true
						? <div className='halfWidth pointer flexCenter option_selector_button text_main bold' onClick={()=>{setChat(true)}}>Quick a.i. chat</div>
						: <div className='halfWidth pointer flexCenter option_selector_button halfOpacity text_main bold' onClick={()=>{setChat(true)}}>Quick a.i. chat</div>
					}
					{chat === false
						? <div className='halfWidth pointer flexCenter option_selector_button text_main bold' onClick={()=>{setChat(false)}}>Send me a message</div>
						: <div className='halfWidth pointer flexCenter option_selector_button halfOpacity text_main bold' onClick={()=>{setChat(false)}}>Send me a message</div>
					}
				</div>
				<div style={{height:10}}></div>
				{chat === true
					? <ChatGPTElement width={width} height={height} />
					: <SendMessage width={width} height={height} />
				}
			</div>
		</div>
	)
}

export default ChatContainer;