import React, { useEffect } from 'react';

const TextAreaComponent = ({value,onChange,placeholder,leftPad,color,fontSize,active,id,highlighted,enter_clicked}) => {

	useEffect(()=>{
		let timer = null
		if(active === true){
			timer = setTimeout(()=>{
				if(highlighted === true){
					document.getElementById(id).select();
				} else {
					document.getElementById(id).focus();
				}
			},40);
		}
		return() => {
			clearTimeout(timer);
		}
	},[active,id,highlighted]);

	function keyPressed(e) {
		if(e.code === 'Enter'){
			enter_clicked()
		}
	}

	let padding = 10;
	if(leftPad !== undefined) padding = leftPad;

	let inputColor = 'black';
	if(color !== undefined) inputColor = color;
	return <textarea className='textArea mainText' placeholder={placeholder} value={value} onChange={(e)=>{onChange(e.target.value)}}
					onKeyPress={keyPressed} style={{width:'100%',height:'100%',paddingLeft:padding,fontSize:fontSize,color:inputColor}} id={id} />
}

export default TextAreaComponent;