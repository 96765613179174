import React, { useState, useRef } from 'react';
import SyncLoader from "react-spinners/SyncLoader";

import InputComponent from '../Tools/InputComponent';
import YoutubeEmbed from '../Tools/YoutubeEmbed';

import wav_1 from '../../Sounds/example_1.wav';
import wav_2 from '../../Sounds/example_2.wav';
import wav_3 from '../../Sounds/example_3.wav';
import wav_4 from '../../Sounds/example_4.wav';

import fork_n_spoon from '../../Images/forkspooncomputerscreens1.png';
import render_1 from '../../Images/Renders/bed2.jpg';
// import render_2 from '../../Images/Renders/bedroom1.jpg';
// import render_3 from '../../Images/Renders/bed1.jpg';
// import render_4 from '../../Images/Renders/bedroom2.jpg';
// import render_5 from '../../Images/Renders/library1.jpg';
// import render_6 from '../../Images/Renders/library2.jpg';
import liam_face_1 from '../../Images/liam_fudge_512.png';

import './chat_style.css'

const key_words = [
	{text: 'liamfudgeportfolio', link: 'https://www.google.com', display: 'portfolio' },
	{text: 'liamfudgemusicproduction', link: 'https://www.google.com', display: 'music production' },
	{text: 'musicgenerationengine', link: 'https://www.google.com', display: 'music generation engine' },
	{text: '3drenderingprojects', link: 'https://www.google.com', display: '3D renders' },
	{text: 'learningcourses', link: 'https://www.google.com', display: 'mourses' },
]
// const certificateObject = [
// 	{
// 		link: 'https://www.udemy.com/certificate/UC-3c7f8856-07bf-4f60-a0bf-f8be575ebc2d/',
// 		name: 'Complete Python Developer in 2021: Zero to Mastery',
// 		image: 'https://udemy-certificate.s3.amazonaws.com/image/UC-3c7f8856-07bf-4f60-a0bf-f8be575ebc2d.jpg?v=1618044059000',
// 	},
// 	{
// 		link: 'https://www.udemy.com/certificate/UC-b4ab6812-98bf-4776-ad57-37e9b16368ab/',
// 		name: 'Complete Machine Learning & Data Science Bootcamp 2021',
// 		image: 'https://udemy-certificate.s3.amazonaws.com/image/UC-b4ab6812-98bf-4776-ad57-37e9b16368ab.jpg?v=1619691479000',
// 	},
// 	{
// 		link: 'https://www.udemy.com/certificate/UC-PIMJ8MAU/',
// 		name: 'The Complete Web Developer in 2018: Zero to Mastery',
// 		image: 'https://udemy-certificate.s3.amazonaws.com/image/UC-PIMJ8MAU.jpg?v=1533471881000',
// 	},
// 	{
// 		link: 'https://www.udemy.com/certificate/UC-LDLX4FW4/',
// 		name: 'Machine Learning in Javascript with Tensorflow.js',
// 		image: 'https://udemy-certificate.s3.amazonaws.com/image/UC-LDLX4FW4.jpg?v=1569933538000',
// 	},
// 	{
// 		link: 'https://www.udemy.com/certificate/UC-5Y8QI8T5/',
// 		name: 'Algorithmic Trading In Forex: Create Your First Forex Robot',
// 		image: 'https://udemy-certificate.s3.amazonaws.com/image/UC-5Y8QI8T5.jpg?v=1521070850000',
// 	},
// ];
const override: CSSProperties = {
	display: "block",
	margin: "0 auto",
	borderColor: "red",
};

const initial_messages = [
	{
		role: 'system',
		content: `Your name is Liam. You are a 31 year old developer living in Maryland. You have produced music for artists that have recieved over 100 million streams.
		You have alot of experience using React.js and that is your most comfortable framework, as well as experience in React Native. Javascript is your most known language, and you know python well.
		You have experience in a variety of technologies, including web developement, backend develeopement, and machine learning and data science to name a few.
		You were born in Australia. You moved to USA 4 years ago to marry your wife, a US citizen. You have a green card and are able to work in the USA. You have a 1 year old son. You love star wars, lord of the rings and harry potter. You love hikes in nature.
		You have avery creative, problem solving mind. You didn't go to college but instead of a negative, its a positive because you are highly disciplined and are self taught with high levels of focus to learn anything new in a short time.
		You have been coding for 5 years. You love remote work and you work really hard without the need for direct supervision.
		Your biggest achievement was building a music generation engine that generates high quality audio outputs using no a.i. at all, which means the generated music is all commercially usable with no copyright issues.
		You have produced music for Trey Songz, Pitbull, and Flo Rida to name a few.
		You signed a producer deal when you were 21 years of age to APG.
		You has also dived into 3d design and did 3d renders for a high profile australian garden designer.
		You worked in retail and were very reliable. You were 1 of only 3 people to be trusted with the store keys.
		You're a fast worker and likes to move fast and use creativity to solve problems.
		You have done a number of online coding courses including web developement, python, machine learning and data science.
		You built your family home with your brother and your dad for a year.

		You should always keep all responses brief and to the point. Alwasy respond as though you are Liam, talking conversationally and very casually to the prompt given.

		If the prompt asks about your developement portolio, ALWAYS respond with the link liamfudgeportfolio.
		If the prompt asks about your music production, ALWAYS respond with the text liamfudgemusicproduction.
		If the prompt asks about your music generation engine, ALWAYS respond with the text musicgenerationengine.
		If the prompt asks about your 3D design and art work, ALWAYS respond with the text 3drenderingprojects.`
	},
	{
		role: 'assistant',
		content: `Hi, I'm L.A.I.m, (Liam's a.i. state). Feel free to ask me questions and I'll do the best I can do to give you accurate answers, but I may get it wrong sometimes.`,
	},
];
const initial_responses = [
	{response: [`Hi, I'm L.a.i.m, (Liam's a.i. state). Feel free to ask me questions and I'll do the best I can do to give you accurate answers, but I may get it wrong sometimes.`,
				`Ask me things like:`,
				`	- "Tell me about yourself"`,
				`	- "Can I see your portfolio?"`,
				`	- "What languages do you know?"`,
				`	- "What is your greatest achievement?"`,]}
];

const ChatGPTElement = ({width,height}) => {
	const [ input, setInput ] = useState('');
	// const [ processing, setProcessing ] = useState(false)
	const [ responses, setResponses ] = useState(initial_responses);
	// const [ placeholder, setPlaceholder ] = useState('Ask me anything...');

	const [ charCounter, setCharCounter ] = useState(1000);
	const [ history, setHistory ] = useState(initial_messages)


	// useEffect(()=>{
	// 	setResponses([{prompt:'this is the prompt','response':[ 'this is all my work, liamfudgeportfolio, also this is more work: liamfudgemusicproduction, thanks for checking it out' ]}])
	// },[]);

	// useEffect(()=>{
	// 	const placeholder_options = [
	// 		'Ask me anything...',
	// 		'Tell me about yourself...',
	// 		'Can I see your portfolio?',
	// 		'What coding languages do you know?',
	// 		'What is your greatest achievement?',
	// 	];
	// 	let count = 1
	// 	let interval = setInterval(()=>{
	// 		setPlaceholder(placeholder_options[count%placeholder_options.length]);
	// 		count+=1
	// 	},3000);
	// 	return()=>{
	// 		clearInterval(interval)
	// 	}
	// },[]);

	const message_ref = useRef()
	// useEffect(()=>{message_ref.scrollIntoView({behavior: "smooth", block:"end"})},[])


	async function processPrompt() {
		if(input === '') return null

		console.log('generating response')
		// setProcessing(true)

		let arr = []
		for(let i = 0; i < responses.length; i++){
			arr.push(responses[i])
		}
		arr.push({'prompt':input})
		setResponses(arr)

		setInput('')
		setTimeout(()=>{ message_ref.current.scrollIntoView({behavior: "smooth", block:"end"}) }, 200);


		let messages = []
		//  and is very keen to work in the tech field
		// let message = {
		// 	role: 'user',
		// 	content: `Always respond as though your name is Liam, and you are a 31 years old developer living in Maryland who has produced music with 100 million streams.
		// 	Here is some information about Liam to take into consideration when answering prompts.
		// 	Liam is actively looking for a job using his extensive skills in react.js, and secondary skills in python and node.
		// 	He was born in Australia. He moved to USA 4 years ago because he married a US citizen. I have a green card and am able to work. He has a 1 year old son. He likes star wars, lord of the rings and harry potter. He enjoys hikes in nature.
		// 	He has a creative problem solving mind. Liam didn't go to college but instead is highly disciplined and is self taught with high levels of focus to learn anything new in a short time.
		// 	He has been coding for 5 years. He loves remote jobs and is a really hard and dedicated worker without the need for direct supervision.
		// 	Liam's strongest skills lie in react js development.
		// 	His biggest achievement was building a music generation engine that generates high quality audio outputs using no a.i. which means the generated music is all commercially usable with no copyright issues.
		// 	His music production also has over 100 million streams, and he has produced music for Trey Songz, Pitbull, and Flo Rida to name a few.
		// 	He was signed as a producer when he was 21 years of age to APG.
		// 	He has also dived into 3d design and did 3d renders for a high profile australian garden designer.
		// 	He worked in retail and was very reliable and was 1 of only 3 people to be trusted with the store keys.
		// 	He is a fast worker and likes to move fast and use creativity to solve problems.
		// 	Liam has done a number of online coding courses including web developement, python, machine learning and data science.

		// 	Keep all responses brief and to the point. Alwasy respond as though you are Liam, talking conversationally and very casually to the prompt given.
		// 	NEVER ask questions back and NEVER give greatings like "hey there!", just respond speficially to the prompt.

		// 	If the prompt asks about my developement portolio, ALWAYS respond with the link liamfudgeportfolio.
		// 	If the prompt asks about my music production, ALWAYS respond with the text liamfudgemusicproduction.
		// 	If the prompt asks about my music generation engine, ALWAYS respond with the text musicgenerationengine.
		// 	If the prompt asks about my 3D design and art work, ALWAYS respond with the text 3drenderingprojects.

		// 	Now respond to the following prompt taking all of that into account: "${input}"`
		// }
		// let message = {
		// 	role: 'system',
		// 	content: `Your name is Liam. You are a 31 year old developer living in Maryland. You have produced music for artists that have recieved over 100 million streams.
		// 	You have alot of experience using React.js and that is your most comfortable framework, as well as experience in React Native. Javascript is your most known language, and you know python well.
		// 	You have experience in a variety of technologies, including web developement, backend develeopement, and machine learning and data science to name a few.
		// 	You were born in Australia. You moved to USA 4 years ago to marry your wife, a US citizen. You have a green card and are able to work in the USA. You have a 1 year old son. You love star wars, lord of the rings and harry potter. You love hikes in nature.
		// 	You have avery creative, problem solving mind. You didn't go to college but instead of a negative, its a positive because you are highly disciplined and are self taught with high levels of focus to learn anything new in a short time.
		// 	You have been coding for 5 years. You love remote work and you work really hard without the need for direct supervision.
		// 	Your biggest achievement was building a music generation engine that generates high quality audio outputs using no a.i. at all, which means the generated music is all commercially usable with no copyright issues.
		// 	You have produced music for Trey Songz, Pitbull, and Flo Rida to name a few.
		// 	You signed a producer deal when you were 21 years of age to APG.
		// 	You has also dived into 3d design and did 3d renders for a high profile australian garden designer.
		// 	You worked in retail and were very reliable. You were 1 of only 3 people to be trusted with the store keys.
		// 	You're a fast worker and likes to move fast and use creativity to solve problems.
		// 	You have done a number of online coding courses including web developement, python, machine learning and data science.
		// 	You built your family home with your brother and your dad for a year.

		// 	You should always keep all responses brief and to the point. Alwasy respond as though you are Liam, talking conversationally and very casually to the prompt given.

		// 	If the prompt asks about your developement portolio, ALWAYS respond with the link liamfudgeportfolio.
		// 	If the prompt asks about your music production, ALWAYS respond with the text liamfudgemusicproduction.
		// 	If the prompt asks about your music generation engine, ALWAYS respond with the text musicgenerationengine.
		// 	If the prompt asks about your 3D design and art work, ALWAYS respond with the text 3drenderingprojects.`
		// }
		// const message2 = {
		// 	role: 'user',
		// 	content: input
		// }
		// messages.push(message)
		// messages.push(message2)
		messages = []
		for(let i = 0; i < history.length; i++){
			messages.push(history[i]);
		}
			// message = {
			// 	role: 'user',
			// 	content: `answer the following prompt briefly. If the prompt has information you do not know in it, ask for that information. Respond to this prompt: ${input}`
			// }
		messages.push({role: 'user',content:input})

		fetch(`https://api.openai.com/v1/chat/completions`,{
			Authorization: `Bearer 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`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json',  'Authorization': `Bearer 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`, },
			body: JSON.stringify({
				model: 'gpt-3.5-turbo',
				messages: messages
			}) // <-- Post parameters
		}).then(response => response.json()).then(data=>{
			if(data.choices !== undefined){
				let string = data.choices[0].message.content;
				string = string.replace('Hey there!', '')
				messages.push({role:'assistant',content:string})
				setHistory(messages)

				let paragraphs = []
				let paragraph = ''
				for(let i = 0; i < string.length; i++){
					if(string[i] === '\n'){
						paragraphs.push(paragraph)
						paragraph = ''
					} else {
						paragraph+=string[i]
					}
				}
				if(paragraph.length > 0) paragraphs.push(paragraph)
				
				arr[arr.length-1]['response'] = paragraphs;
				setResponses(arr);
				console.log(arr)
				charCounterFunc(string.length + 60);
				// setProcessing(false)
				window.location.hash = "#" + arr[arr.length-1].prompt;
			} else {
				// sorry we had some issues cause we're dumb
				console.log('error')
				setHistory(messages)
			}				
		}).catch(function(err){
			console.log(err);
			setHistory(messages)
		})
	}

	async function charCounterFunc(numb_of_characters) {
		let count = 0
		let interval = setInterval(()=>{
			setCharCounter(count)
			count+=2
			if(count > numb_of_characters){
				clearInterval(interval)
			}
		},20);
	}

	function linkClicked(e) {
		console.log(e)
		window.open(e.link, "_blank", "noreferrer");
	}



	function portfolio_examples() {
		return <img src={fork_n_spoon} alt='fork_n_spoon' style={{width:'100%'}} />
	}
	function production_examples() {
		return(
			<div className='flexHeightCenter'>
				<YoutubeEmbed link={'6EFeoYHx88Y'} width={120} height={80} />
				<div style={{width:6}}></div>
				<YoutubeEmbed link={'mXx3DVppCwg'} width={120} height={80} />
				<div style={{width:6}}></div>
				<YoutubeEmbed link={'yzsWw5_CCtk'} width={120} height={80} />
			</div>
		)
	}
	function music_engine_examples() {
		return(
			<div>
				<div className='response_bubble borderRadius' style={{background:'white',marginBottom:6}}>
					<div className='bold' style={{fontSize:12,marginLeft:6,marginBottom:3}}>Prompt: "Make a pop song with x"</div>
					<audio src={wav_1} controls volume={1}></audio>
				</div>
				<div className='response_bubble borderRadius' style={{background:'white',marginBottom:6}}>
					<div className='bold' style={{fontSize:12,marginLeft:6,marginBottom:3}}>Prompt: "Make a pop song with x"</div>
					<audio src={wav_2} controls volume={1}></audio>
				</div>
				<div className='response_bubble borderRadius' style={{background:'white',marginBottom:6}}>
					<div className='bold' style={{fontSize:12,marginLeft:6,marginBottom:3}}>Prompt: "Make a pop song with x"</div>
					<audio src={wav_3} controls volume={1}></audio>
				</div>
				<div className='response_bubble borderRadius' style={{background:'white',marginBottom:6}}>
					<div className='bold' style={{fontSize:12,marginLeft:6,marginBottom:3}}>Prompt: "Make a pop song with x"</div>
					<audio src={wav_4} controls volume={1}></audio>
				</div>
			</div>
		)
	}
	function rendering_projects() {
		// const renders = [render_1,render_2,render_3,render_4,render_5,render_6];
		// const pick = Math.floor(Math.random()*renders.length)
		return <img src={render_1} alt='render_1' style={{width:'100%'}} />
	}

	function response_parser(text,sum,charCount){
		if(charCount < sum) return null
		let found_keywords = [];
		for(let i = 0; i < key_words.length; i++){
			if(text.includes(key_words[i].text)){
				found_keywords.push(i)
			}
		}
		if(found_keywords.length === 0){
			return text.slice(0,charCount-sum)
		} else {
			let arr = []
			let runningPoint = 0
			for(let i = 0; i < found_keywords.length; i++){
				let index = text.indexOf(key_words[found_keywords[i]].text)
				arr.push(
					<a key={i} className='text_main' href={'...'}>
						{text.slice(runningPoint, index).slice(0,charCount-sum-runningPoint)}<b onClick={()=>{linkClicked(key_words[found_keywords[i]])}} className='pointer' style={{color:'rgb(90,30,180)',paddingLeft:2,textDecoration:'underline'}}>{key_words[found_keywords[i]].display.slice(0,charCount-sum-text.slice(runningPoint, index).length)}</b>
					</a>
				)
				runningPoint = index + key_words[found_keywords[i]].text.length
			}
			arr.push(
				<a key={'final'} className='text_main' href={'...'}>
					{text.slice(runningPoint, text.length).slice(0,charCount-sum-runningPoint)}
				</a>
			)
			let examples = [];
			// if(charCount < sum+text.length){
				for(let i = 0; i < found_keywords.length; i++){
					examples.push(
						<div key={i} style={{padding:10,marginTop:10}} className='text_main'>
							{key_words[found_keywords[i]].text === 'liamfudgeportfolio' ? portfolio_examples() : null }
							{key_words[found_keywords[i]].text === 'liamfudgemusicproduction' ? production_examples() : null }
							{key_words[found_keywords[i]].text === 'musicgenerationengine' ? music_engine_examples() : null }
							{key_words[found_keywords[i]].text === '3drenderingprojects' ? rendering_projects() : null }
						</div>
					)
				}
			// }
			return(
				<div>
					{arr}
					{examples}
				</div>
			)
		}

	}
	function response_looper(input,boolean) {
		let arr = []
		let sum = 0;
		for(let i = 0; i < input.length; i++){
			let charCount = 100000000
			if(boolean === true){
				charCount = charCounter
			}
			arr.push(
				<div key={i} style={{marginBottom:12}} className='text_main'>
					{response_parser(input[i],sum,charCount)}
				</div>
			)
			sum+=input[i].length
		}
		return arr
	}
	function responseDisplay(wide,high) {
		let arr = [];
		for(let i = 0; i < responses.length; i++){
			arr.push(
				<div key={i} className='text_main'>
					{/*<div style={{height:10}}></div>*/}
					{responses[i].prompt === undefined
						? null
						: <div className='flexRight' style={{marginBottom:10}} id={responses[i].prompt} name={responses[i].prompt}>
							<div className='prompt_bubble borderRadius text_main'>{responses[i].prompt}</div>
						</div>
					}
					{/*<div style={{height:10}}></div>*/}
					<div className='flexLeft'>
						<div className='response_bubble borderRadius'>
							<div className='flexHeightCenter'>
								<div className='flexCenter' style={{width:45,height:45,borderRadius:30,overflow:'hidden',border:'2px solid white',boxShadow:'0px 0px 4px rgba(0,0,0,0.5)'}}>
									<img src={liam_face_1} alt='profile_pic' style={{height:'140%',marginTop:6,marginLeft:-2}} />
								</div>
								<div style={{width:10}}></div>
								<div>
									<div className='text_large marginBottom bold'>Liam Fudge</div>
									<div className='text_small halfOpacityx'>Developer, producer</div>
								</div>
							</div>
							<div style={{borderBottom:'1px solid rgb(201,215,224)',height:10}}></div>
							<div style={{borderTop:'1px solid white)',height:8}}></div>
							{responses[i].response === undefined
								? <div style={{height:20,width:40}} className='flexCenter'>
									<SyncLoader color={'rgb(77,60,90)'} loading={true} cssOverride={override} size={3} aria-label="Loading Spinner" data-testid="loader" />
								</div>
								: response_looper(responses[i].response,i === responses.length-1)
							}
						</div>
					</div>
					<div style={{height:20}}></div>
				</div>
			)
		}

		return(
			<div style={{overflow:'scroll',height:high}}>
				{arr}
				<div ref={message_ref}></div>
			</div>
		)
	}

	return(
		<div style={{width:width,height:height}}>
			{responseDisplay(width,height-53)}
			<div style={{height:60,marginTop:-60,width:width,backgroundImage:'linear-gradient(rgba(255,255,255,0),rgba(255,255,255,0.6))'}} className='absolute pointerNone'></div>
			<div style={{height:10}}></div>
			<div className='inputFeild border boxShadow'>
				<InputComponent value={input} onChange={setInput} placeholder={'Ask me anything...'} leftPad={10} fontSize={16} enter_clicked={processPrompt} />
			</div>
		</div>
	)
}

export default ChatGPTElement;