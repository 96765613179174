import React, { useState, useEffect } from 'react';

import firebase from './firebase';

import MainRoute from './Routes/MainRoute';

import './App.css';

if(firebase === '') console.log(null);

const App = () => {
	const [ scroll, setScroll ] = useState(0);
	const [ dimensions, setDismensions ] = useState({height:0,width:0});

	// const [ chat, setChat ] = useState(true)
	const [ modal, setModal ] = useState('');
	const [ sound, setSound ] = useState('');

	function setModalFunction(e) {
		setModal(e)
		if(sound !== '' && e === ''){
			sound.stop()
			setSound('')
		}
	}

	// Handles all app size changes
		useEffect(()=>{
			setDismensions({height:document.documentElement.clientHeight,
			width:document.documentElement.clientWidth});
			window.addEventListener('resize', init);
			return () => {
				window.removeEventListener('resize', init);
			}
		},[]);
		function init() {
			setDismensions({height:document.documentElement.clientHeight,
			width:document.documentElement.clientWidth});
		}

	// GET MOUSE SCROLL POSITION
		useEffect(()=>{
			// if(modal === ''){
				window.addEventListener('scroll', handleScroll, { passive: true });
				// window.removeEventListener('wheel', preventDefault, false);
				function handleScroll(e) {
					let scrollPos = (document.body.getBoundingClientRect()).top;
					if(scrollPos < 0){
						scrollPos = Number(String(scrollPos).slice(1));
					}
					setScroll(scrollPos);
				}
				// function preventDefault(e) {
				// 	e = e || window.event
				// 	if (e.preventDefault) {
				// 		e.preventDefault()
				// 	}
				// 	e.returnValue = false
				// }
			// } else {
			// 	// document.addEventListener('wheel', preventDefault, { passive: false })
			// 	// function preventDefault(e) {
			// 	// 	e = e || window.event
			// 	// 	if (e.preventDefault) {
			// 	// 		e.preventDefault()
			// 	// 	}
			// 	// 	e.returnValue = false
			// 	// }
			// }
		},[]);
			
	return <MainRoute dimensions={dimensions} scroll={scroll} modal={modal} setModal={setModalFunction} sound={sound} setSound={setSound} />
}

export default App;
