import React, { useEffect, useState } from 'react';
import {Howl} from 'howler';

// import example_1 from '../../Sounds/example_1.wav';
// import example_2 from '../../Sounds/example_2.wav';
// import example_3 from '../../Sounds/example_3.wav';
// import example_4 from '../../Sounds/example_4.wav';
import drillin_and_chilling from '../../Sounds/drillin_and_chilling.m4a';
import will_to_survive from '../../Sounds/will_to_survive.m4a';
import eightys_1 from '../../Sounds/80s_1.mp3';
import eightys_2 from '../../Sounds/80s_2.mp3';

import Cross from '../../Images/x.svg';
import play from '../../Images/play_circle_white.svg';
import pause from '../../Images/pause_circle_white.svg';
// import chevron_thin_left_white from '../../Images/chevron_thin_left_white.svg';
import Chevron from '../../Images/chevron-left.svg';

const audio_loops = [
	{
		name: 'Generation 1',
		sound: eightys_2,
	},
	{
		name: 'Generation 2',
		sound: will_to_survive,
	},
	{
		name: 'Generation 3',
		sound: drillin_and_chilling,
	},
	{
		name: 'Generation 4',
		sound: eightys_1,
	},
]

const EnginePortfolio = ({dimensions,setModal,sound,setSound}) => {
	// const [ sound, setSound ] = useState('')
	const [ number, setNumber ] = useState(0)

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return ()=> document.body.style.overflow = 'unset';
	}, []);

	function play_sound(file) {
		if(sound !== ''){
			sound.stop()
			setSound('')
		} else {
			var input_sound = new Howl({
				src: [file]
			});
			input_sound.play()
			setSound(input_sound)
		}
	}

	function previousProject() {
		if(sound !== '') play_sound()
		setNumber(number-1)
	}
	function nextProject() {
		if(sound !== '') play_sound()
		setNumber(number+1)
	}

	function audio_player(wide,high) {
		return(
			<div>
			<div className='flexCenter'>
				<div style={{width:1}} className='flexCenter'>
					<div>
						<div style={{marginLeft:-60,marginTop:-30,height:60,width:60}} className='absolute pointer flexCenter' onClick={previousProject}><img src={Chevron} alt='Chevron' style={{height:42}} /></div>
					</div>
				</div>
				<div style={{width:width,overflow:'hidden'}}
						className='flexCenter pointerx hoverOpacityDownx'>
					<div className='black_background white_color textAlignCenter text_largest' style={{padding:20,borderRadius:12,width:width-40}} onClick={()=>play_sound(audio_loops[(number+(audio_loops.length*10))%audio_loops.length]['sound'])}>
						<div style={{height:10}}></div>
						<div className='flexCenter'>
							{sound === ''
								? <img src={play} alt='fewf' style={{height:60}} className='pointer zoomInOnHover' />
								: <img src={pause} alt='fewf' style={{height:60}} className='pointer zoomInOnHover' />
							}
						</div>
						<div style={{height:10}}></div>
						{audio_loops[(number+(audio_loops.length*10))%audio_loops.length]['name']}
					</div>
					{/*{arr[(number+(arr.length*10)) % arr.length]}*/}
				</div>
				<div style={{width:1,height:'100%'}} className='flexCenter'>
					<div>
						<div style={{marginRight:-60,marginTop:-30,height:60,width:60}} className='absolute pointer flexCenter' onClick={nextProject}><img src={Chevron} alt='Chevron' style={{height:42,transform:'rotate(180deg)'}} /></div>
					</div>
				</div>
			</div>
			{/*<div className='flexLeft' style={{overflow:'scroll',width:width-6,border:'3px solid white'}}>{arr}</div>*/}
			</div>
		)
		// return(
		// 	<div className='black_background white_color textAlignCenter text_largest' style={{padding:20,borderRadius:12}} onClick={()=>play_sound(audio_loops[(number+(audio_loops.length*10))%audio_loops.length]['sound'])}>
		// 		<div style={{height:10}}></div>
		// 		<div className='flexCenter'>
		// 			{sound === ''
		// 				? <img src={play} alt='fewf' style={{height:60}} className='pointer zoomInOnHover' />
		// 				: <img src={pause} alt='fewf' style={{height:60}} className='pointer zoomInOnHover' />
		// 			}
		// 		</div>
		// 		<div style={{height:10}}></div>
		// 		{audio_loops[(number+(audio_loops.length*10))%audio_loops.length]['name']}
		// 	</div>
		// )
	}

	let width = 900
	let height = 900
	if(dimensions.width < 900+80){
		width = dimensions.width-80
	}
	if(dimensions.height < 900+80){
		height = dimensions.height-80
	}
	return(
		<div style={{width:width}} className='text_main'>
			<div className='flexRight' style={{width:width}}>
				<div>
					<div className='absolute white_background flexCenter pointer' style={{height:60,width:60,marginLeft:-60,zIndex:20,borderRadius:14}}
							onClick={()=>{setModal('')}}>
						<img src={Cross} alt='Cross' style={{height:28}} />
					</div>
				</div>
			</div>
			<div className='text_sub_hero bold white_background relative flexHeightCenter' style={{boxShadow:'0px 0px 12px rgba(0,0,0,0.2)',height:60,paddingLeft:10}}>Music Generation Engine</div>

			<div className='' style={{padding:10}}>
				<div >I built an project using python that is a more powerful music generator than the in browser version I built ("https://www.songhatch.com"). This engine outputs a high quality audio file that has been generated from scratch. This whole project uses no A.I in the process of creating music, meaning everything generated by the system is completely commercially useable and free of any copyright concerns which is a problem of generative A.I models.</div>

				<div className='bold textAlignCenter uppercase text_sub_hero' style={{marginTop:20,marginBottom:10}}>Audio examples</div>

				{audio_player(width,height)}
			</div>
		</div>
	)
}

export default EnginePortfolio;

