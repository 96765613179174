import React, { useState, useEffect } from 'react';

import Chevron from '../../Images/chevron-left.svg';
import zillowhome from '../../Images/screenshots/zillowhome.jpg';
import mobilezillow from '../../Images/screenshots/mobilezillow.jpg';
import dancingavatar2 from '../../Images/screenshots/dancingavatar2.jpg';
import mobiledance2 from '../../Images/screenshots/mobiledance2.jpg';
import netflixpickerhome from '../../Images/screenshots/netflixpickerhome.jpg';
import mobilenetflix from '../../Images/screenshots/mobilenetflix.jpg';
import massagehome from '../../Images/screenshots/massagehome.jpg';
import mobilemassage from '../../Images/screenshots/mobilemassage.jpg';
import hpquizhome2 from '../../Images/screenshots/hpquizhome2.jpg';
import mobilehpquiz from '../../Images/screenshots/mobilehpquiz.jpg';
import triktreathome from '../../Images/screenshots/triktreathome.jpg';
import mobiletriktreat from '../../Images/screenshots/mobiletriktreat.jpg';
import finvishome from '../../Images/screenshots/finvishome.jpg';
import mobilefinvis from '../../Images/screenshots/mobilefinvis.jpg';
import visrenderhome from '../../Images/screenshots/visrenderhome.jpg';
import mobilevisrender from '../../Images/screenshots/mobilevisrender.jpg';
import ideashome from '../../Images/screenshots/ideashome.jpg';
import mobileideas from '../../Images/screenshots/mobileideas.jpg';
import blackjackhome from '../../Images/screenshots/blackjackhome.jpg';
import mobileblackjack from '../../Images/screenshots/mobileblackjack.jpg';
import tender from '../../Images/screenshots/tender.png';
import tinder_ss_2 from '../../Images/tinder_ss_2.png';
import songhatch from '../../Images/screenshots/songhatch.png';
import mobilesonghatch from '../../Images/screenshots/mobilesonghatch2.png';
import forkspooncomputerscreens1 from '../../Images/forkspooncomputerscreens1.png';

import arrow_left from '../../Images/arrow-left.svg';
import tender_pitch_export_med from '../../Images/tender_pitch_export_med.mp4';
import Cross from '../../Images/x.svg';

const projectList = [
	{
		name: `SongHatch`,
		description: `Music is a passion for me and I've also had some experience producing for major label artists. This project is a music generator. This version generates MIDI in a variety of genres. Having the restriction of this all existing in the browser meant that the audio quality isn't at the highest quality level, so I built a high quality audio engine that generates commercially useable audio that takes the midi from this system as input. There is no A.I. used in this product, (except for a language model that takes the user prompt and turns it into instructions for the generation process), meaning that there is no possibility for copyright infringement, and all the music generated is legally useable and unique.`,
		tech: ['React','Nodejs','Firebase'],
		link: 'https://songhatch.com',
		desktop: songhatch,
		mobile: mobilesonghatch,
	},
	{
		name: `PriviChef`,
		description: `PriviChef is a platform for users to have chefs prepare restaurant quality meals in the comfort of their home. I built a React web application as well as React-Native mobile apps. There is a customer version of the app, where customers search chefs and make bookings, there is a chef version for organising and co-ordinating the process of cooking in customers homes. There is a final version for restaurants to join the platform and hire chefs to go out and cook their food.`,
		tech: ['React','React-Native','Nodejs'],
		link: 'N/A',
		desktop: forkspooncomputerscreens1,
		mobile: '',
	},
	{
		name: `Tender`,
		description: `My brother is an architect and he had come up with an idea for a Tinder like product for matching people interested in building their own home with a professional who could construct that house for them. I built a React Native application and made a promotional video to show how it could work.`,
		tech: ['React','Firebase'],
		link: 'https://blackjack-card-counter.web.app/',
		desktop: tender,
		mobile: tinder_ss_2,
		video: tender_pitch_export_med,
	},
	{
		name: `Lo-Home`,
		description: `While on zillow.com I saw an opotunity to use some data that they had hidden away to make a product that could really help investors in real estate find the best deals. Zillow has what % the property is of the zip code average price on most properties. I used Nodejs to scrape zillow.com for the data I needed, saved it to a Firebase database, and then built a React frontend for the project.`,
		tech: ['React','Nodejs','Firebase','Webscraping'],
		link: 'https://newzillow-5d3e8.web.app/',
		desktop: zillowhome,
		mobile: mobilezillow,
	},
	{
		name: `TrikTreat`,
		description: `My friend and I came up with a concept for building an app that could offer kids a virtual "trick-ortreat" experience in the safety of their own homes with the current covid situation. I used React Native to build an app that allowed users to signup on behalf of their children and create accounts for them, then find their childrens class and add themselves to the class so they can participate in an online event. When the time of the event was reached the students would be paired randomly with other students in video chats for 1 minute and be able to send jump scares to scare their friends and rate the friends costumes. We made it safe so any user could block any other user and only be paired with people they actually knew.`,
		tech: ['React-Native','WebRTC','Firebase'],
		link: 'https://www.triktreat.com/',
		desktop: triktreathome,
		mobile: mobiletriktreat,
	},
	{
		name: `Dance-Cube`,
		description: `I was looking at TensorFlow and one of their models was tracking a human bodies movements and it sparked an idea. I thought I could use that to track a users body, then map the movements onto a 3d model using Threejs. It turned out to be harder than i thought because the TensorFlow model only gave 2 dimensional coordinates and without the 3rd dimension, it was basically impossible to accuratly map a users movements to a 3d model. I decided to simplify my idea and I used a much more friendly model I created with a lot less moveable joints than an actual human, and then just gave it basic movement. Thats when I decided I needed to get into machine learning, so I did some courses online about machine learning, and I will create my own model one day and train it to get the 3rd dimension I need to really make it possible to track a human body and in real time map its movements to a Threejs model.`,
		tech: ['React','TensorFlow','Threejs','Firebase'],
		link: 'https://dexample-57015.web.app/',
		desktop: dancingavatar2,
		mobile: mobiledance2,
	},
	{
		name: `Bowral Massage Therapy`,
		description: `This was a website I created for a local massage therapy. The client wanted to use a variety of Australian native plants and keep the feel very peaceful and relaxing. I kept it quite sparse and used smooth css animations to let the transitions between pages flow smoothly.`,
		tech: ['React','Firebase'],
		link: 'https://bowralmassagetherapy.com.au/',
		desktop: massagehome,
		mobile: mobilemassage,
	},
	
	
	{
		name: `Harry Potter Quiz`,
		description: `I am a huge Harry Potter fan so I just wanted to make the ultimate Harry Potter quiz app. Nothing too special here, just a React app with a simple modern design with some questions for users to answer.`,
		tech: ['React','Firebase'],
		link: 'https://ultimate-harry-potter-qu-412ee.web.app/',
		desktop: hpquizhome2,
		mobile: mobilehpquiz,
	},
	
	{
		name: `Finvis`,
		description: `This is just a budget app I wanted to make. I had bigger ideas for this to be honest, but I haven't had the time yet to add everything I initially wanted.`,
		tech: ['React','Firebase'],
		link: 'https://budget-dfd6f.web.app/',
		desktop: finvishome,
		mobile: mobilefinvis,
	},
	{
		name: `Vis-Render`,
		description: `I love to make 3d Renders of houses designs and I strive to make them as realistic as possible. I wanted to make a website displaying some of my work, but I wanted to present it in a creative way. I wanted to move the user through the site in a different way. I set the whole background as a movie file of some of my renders, and I made websites scroll control the playback of the video, so as you scroll down the page the video plays and it gives a nice feel to the user.`,
		tech: ['React','Firebase'],
		link: 'https://architectrual-rendering.web.app/',
		desktop: visrenderhome,
		mobile: mobilevisrender,
	},
	{
		name: `LetsPix`,
		description: `I saw a post on facebook saying, "I dont know if this is a good idea, but wouldn't a cool app be a Tinder for Netflix, where you and your partner swipe on shows you would like to watch, and then it shows you all your matches." I thought that seemed like a fun project so built a serverless React app using Firebase to store users and save their movie picks.`,
		tech: ['React','Firebase'],
		link: 'https://netflixpicker.web.app/',
		desktop: netflixpickerhome,
		mobile: mobilenetflix,
	},
	{
		name: `Idea-generator`,
		description: `I am always trying to come up with ideas for websites, so I decided that a solution to help me think of ideas could be an ideas generator. You have a list of industries, a list of technologies and a monetization list, and each time you click on the random generate button, it picks a random item from each list, pairing them together to hopefully come up with the next big idea.`,
		tech: ['React','Firebase'],
		link: 'https://idea-generator-d0795.web.app/',
		desktop: ideashome,
		mobile: mobileideas,
	},
	{
		name: `Blackjack Card Counter`,
		description: `I saw the movie 21 and got interested in how card counting works. I did some research and used the information professionals gave about card counting to let the computer do the heavy lifting and count the cards and make recomendations on bet sizing too. Just a fun little project I did a while back to practice my skills.`,
		tech: ['React','Firebase'],
		link: 'https://blackjack-card-counter.web.app/',
		desktop: blackjackhome,
		mobile: mobileblackjack,
	},
]

const DevelopementPortfolio = ({dimensions,setModal}) => {
	const [ selected, setSelected ] = useState(0);
	const [ expandText, setExpandText ] = useState(false);

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return ()=> document.body.style.overflow = 'unset';
	}, []);


	function selectProject(e) { setSelected(e) }
	function nextProject() { setSelected(selected+1) }
	function previousProject() { setSelected(selected-1) }

	function projectListDisplay(width,height) {
		if(selected === ''){
			let arr = [];
			for(let i = 0; i < projectList.length; i++){
				arr.push(
					<div key={i} style={{width:(width)/3-4,height:(width)/3-4,overflow:'hidden',borderRight:'4px solid white',borderBottom:'4px solid white',borderRadius:8}}
							className='flexCenter pointer' onClick={()=>{selectProject(i)}}>
						<img src={projectList[i].desktop} alt='desktop_pic' style={{width:'120%'}} />
					</div>
				)
			}
			return(
				<div className='flexLeft' style={{flexWrap:'wrap',width:width,height:height,overflow:'scroll',borderLeft:'4px solid white',borderTop:'4px solid white'}}>{arr}</div>
			)
		}
		
		const slctd = (selected+projectList.length*6)%projectList.length
		let opacity = 1;
		let blur = 'blur(0px)';
		if(expandText === true){
			opacity = 0.5;
			blur = 'blur(4px)';
		}
		return(
			<div className='flexCenter' style={{height:height}}>
				<div style={{width:1,height:height}} className='flexCenter'>
					<div>
						<div style={{marginLeft:-60,height:80,width:60}} className='absolute pointer flexCenter' onClick={previousProject}><img src={Chevron} alt='Chevron' style={{height:42}} /></div>
					</div>
				</div>
				{projectList[slctd].video === undefined
					? <div style={{opacity:opacity,filter:blur}} onClick={()=>{setExpandText(false)}}>
						{dimensions.width < 500
							? projectList[slctd].mobile === ''
								? <div style={{width:(width),height:(width)*2.2,overflow:'hidden'}} className='flexCenterColumn'>
									<img src={projectList[slctd].desktop} alt='mobile_img' style={{height:'100%'}} />
								</div>
								: <div style={{width:(width),height:(width)*2.2,overflow:'hidden'}} className='flexCenterColumn'>
									<img src={projectList[slctd].mobile} alt='mobile_img' style={{height:'100%'}} />
								</div>
							: <div>
								{projectList[slctd].mobile === ''
									? null
									: <div className='absolute flexCenter flexRight' style={{width:width,height:height-80}}>
										<div style={{width:(height*0.64)*0.5,height:(height*0.64),
												// marginTop:height*0.1,marginLeft:width-(height*0.35),
												marginRight:20,
												borderRadius:12,
												overflow:'hidden',border:'3px solid black',boxShadow:'0px 0px 12px rgba(0,0,0,0.8)'}}
												className='white_background'>
											<img src={projectList[slctd].mobile} alt='mobile_img' style={{width:'100%'}} />
										</div>
									</div>
								}

								{/*<div style={{width:(width),height:(width)-100,overflow:'hidden'}} className='flexCenterColumn'>
									<img src={projectList[slctd].desktop} alt='desktop_pic' style={{width:'100%'}} />
								</div>*/}

								<div style={{width:(width),height:height,overflow:'hidden'}} className='flexCenterColumn'>
									{dimensions.width / dimensions.height > 0.4
										? <img src={projectList[slctd].desktop} alt='desktop_pic' style={{width:'100%'}} />
										: <img src={projectList[slctd].desktop} alt='desktop_pic' style={{height:'100%'}} />
									}
								</div>

							</div>
						}

					</div>
					: <div style={{opacity:opacity,filter:blur}} onClick={()=>{setExpandText(false)}}>
						<div style={{width:(width),height:(width)-100,overflow:'hidden'}} className='flexCenter'>
							<img src={projectList[slctd].video} alt='desktop_pic' style={{width:'100%'}} />
							<video  width={'100%'} id='videoplayer' controls autoPlay>
								<source src={projectList[slctd].video} type='video/mp4' />
							</video>
						</div>
					</div>
				}

				{expandText === true
					? <div className='absolute flexHeightBottom pointerNone' style={{width:width,height:height,borderRadius:12,overflow:'hidden'}}>
						<div style={{width:width,zIndex:20,boxShadow:'0px 0px 12px rgba(0,0,0,0.2)'}} className='white_background relative pointerAuto'>
							<div style={{padding:10}}>
								<div onClick={()=>{setExpandText(false)}} className='pointer bold flexCenter hoverOpacityDown' style={{padding:10}}>Collapse</div>
								<div className='bold text_large' style={{marginBottom:10}}>{projectList[slctd].name}</div>
								<div className='text_main'>{projectList[slctd].description}</div>

								<div className='text_main pointer bold' style={{marginTop:10,marginBottom:4,color:'rgb(50,40,130)'}} onClick={()=>{window.open(projectList[slctd].link, "_blank", "noreferrer")}}>Go to website</div>
							</div>
						</div>
					</div>
					: <div className='absolute flexHeightBottom pointerNone' style={{width:width,height:height,borderRadius:12,overflow:'hidden'}}>
						<div style={{width:width,zIndex:20,boxShadow:'0px 0px 12px rgba(0,0,0,0.2)',height:100}} className='white_background relative pointer pointerAuto' onClick={()=>{setExpandText(true)}}>
							<div style={{padding:10}} className='hoverOpacityDown'>
								<div className='bold text_large' style={{marginBottom:10}}>{projectList[slctd].name}</div>
								<div className='text_main'>{projectList[slctd].description.slice(0,240)}...</div>
								<div style={{width:width,height:60,marginTop:-50,backgroundImage:'linear-gradient(to bottom,rgba(255,255,255,0),white)'}} className='absolute'></div>
							</div>
						</div>
					</div>
				}

				<div style={{width:1,height:height}} className='flexCenter'>
					<div>
						<div style={{marginRight:-60,height:80,width:60}} className='absolute pointer flexCenter' onClick={nextProject}><img src={Chevron} alt='Chevron' style={{height:42,transform:'rotate(180deg)'}} /></div>
					</div>
				</div>
			</div>
		)
	}

	let width = 900
	let height = 900
	if(dimensions.width < 900+120){
		width = dimensions.width-120
	}
	if(dimensions.height < 900+120){
		height = dimensions.height-120
	}
	return(
		<div style={{width:width}}>
			<div className='flexRight' style={{width:width}}>
				<div>
					<div className='absolute white_background flexCenter pointer' style={{height:60,width:60,marginLeft:-60,zIndex:20,borderRadius:14}}
							onClick={()=>{setModal('')}}>
						<img src={Cross} alt='Cross' style={{height:28}} />
					</div>
				</div>
			</div>
			<div className='text_sub_hero bold white_background relative flexHeightCenter' style={{boxShadow:'0px 0px 12px rgba(0,0,0,0.2)',height:60,paddingLeft:10,zIndex:10}}>Developement Portfolio</div>
			{selected === ''
				? null
				: <div className='absolute' style={{zIndex:20}}>
					<div style={{margin:10,height:28,borderRadius:14,paddingLeft:10,paddingRight:14}} onClick={()=>{setSelected('')}}
							className='flexCenter black_background white_color text_smaller bold pointer boxShadow'>
						<img src={arrow_left} alt='arrow_left' style={{width:14,marginTop:1}} />
						Back
					</div>
				</div>
			}
			{projectListDisplay(width,height)}
		</div>
	)
}

export default DevelopementPortfolio;