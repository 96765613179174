import { initializeApp } from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/database';

const firebaseConfig = {
	apiKey: "AIzaSyAFD9RCbcHZhKHD1zSkDjKtNeD5rsFrtHU",
	authDomain: "resume-liam-fudge.firebaseapp.com",
	projectId: "resume-liam-fudge",
	storageBucket: "resume-liam-fudge.appspot.com",
	messagingSenderId: "687184748314",
	appId: "1:687184748314:web:1ae8de9b32ce18a02596a3",
	measurementId: "G-BQ8D8SBLY7"
};

const firebase = initializeApp(firebaseConfig);

export default firebase;