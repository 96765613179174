import React, { useState } from 'react';
import { getFirestore, doc, setDoc } from 'firebase/firestore';

import InputComponent from '../Tools/InputComponent';
import TextAreaComponent from '../Tools/TextAreaComponent';

const SendMessage = ({width,height}) => {
	const [ subject, setSubject ] = useState('');
	const [ message, setMessage ] = useState('');
	const [ from, setFrom ] = useState('');
	const [ thankyou, setThankyou ] = useState(false);

	function subjectEnterClicked(e) {}
	function messageEnterClicked(e) {}
	function fromEnterClicked(e) {}

	async function save_message_to_firebase() {
		const id = String(Math.round(Math.random()*100000001183))
		const db = getFirestore();
		const userRef = doc(db, 'messages', id);
		const object = {
			id: id,
			from: from,
			subject: subject,
			message: message,
			date: String(new Date()),
		}
		setDoc(userRef, object);
		setThankyou(true);
	}


	if(thankyou === true){
		return(
			<div style={{width:width,height:height}} className='flexCenter textAlignCenter'>
				<div style={{width:width-60,}}>
					<div className='bold text_larger'>Thank you</div>
					<div style={{height:20}}></div>
					<div className='text_main'>Your message has been sent. I will try and get back to you as soon as possible!</div>
					<div style={{height:50}}></div>
				</div>
			</div>
		)
	}
	return(
		<div style={{width:width,height:height}}>

			<div className='text_main' style={{marginBottom:8}}>Your email</div>
			<div className='inputFeild border boxShadowx '>
				<InputComponent value={from} onChange={setFrom} placeholder={'yourname@email.com'} leftPad={10} fontSize={16} enter_clicked={fromEnterClicked} />
			</div>
			<div style={{height:10}}></div>

			<div className='text_main' style={{marginBottom:8}}>Subject</div>
			<div className='inputFeild border boxShadowx '>
				<InputComponent value={subject} onChange={setSubject} placeholder={'An enquiry about...'} leftPad={10} fontSize={16} enter_clicked={subjectEnterClicked} />
			</div>

			<div style={{height:10}}></div>

			<div className='text_main' style={{marginBottom:8}}>Message</div>
			<div className='inputFeild border boxShadowx' style={{height:height-224}}>
				<TextAreaComponent value={message} onChange={setMessage} placeholder={'Hi Liam, I wanted to enquire about...'} leftPad={10} fontSize={16} enter_clicked={messageEnterClicked} />
			</div>

			<div style={{height:10}}></div>
			<div className='flexRight'>
				{from === '' || subject === '' || message === ''
					? <div className='button flexCenter bk_main white_color borderRadius pointer' style={{opacity:0.5,pointerEvents:'none'}}>Send message</div>
					: <div className='button flexCenter bk_main white_color borderRadius pointer' onClick={save_message_to_firebase}>Send message</div>
				}
			</div>
		</div>
	)
}

export default SendMessage;