import React, { useState, useEffect } from 'react';

import YoutubeEmbed from '../Tools/YoutubeEmbed';

import Cross from '../../Images/x.svg';

import Chevron from '../../Images/chevron-left.svg';

// const aboutYouLink = 'https://www.youtube.com/watch?v=6EFeoYHx88Y';
// const drinkToThatLink = 'https://www.youtube.com/watch?v=mXx3DVppCwg';
// const rodeoWineLink = 'https://www.youtube.com/watch?v=0qHwr3RWU8w';
// const floRidaLink = 'https://www.youtube.com/watch?v=MToNIKm8nM8';
// const skipMarleyLink = 'https://www.youtube.com/watch?v=yzsWw5_CCtk';
// const stayLink = 'https://www.youtube.com/watch?v=mXHy4xfvq1s';
// const fabLink = 'https://www.youtube.com/watch?v=Fn5j0Ahnq_I';

const musicObject = [
	{
		link: 'https://www.youtube.com/watch?v=6EFeoYHx88Y',
		embed: '6EFeoYHx88Y',
		artist: 'Trey Songz',
		song: 'About You'
	},
	{
		link: 'https://www.youtube.com/watch?v=mXx3DVppCwg',
		embed: 'mXx3DVppCwg',
		artist: 'Jerrod Niemann',
		song: 'Drink to That All Night (feat. Pitbull)'
	},
	{
		link: 'https://www.youtube.com/watch?v=0qHwr3RWU8w',
		embed: '0qHwr3RWU8w',
		artist: 'Nyanda',
		song: 'Rodeo Wine'
	},
	{
		link: 'https://www.youtube.com/watch?v=MToNIKm8nM8',
		embed: 'MToNIKm8nM8',
		artist: 'Flo Rida',
		song: 'How I feel (Twenty1 Radio Mix)'
	},
	{
		link: 'https://www.youtube.com/watch?v=yzsWw5_CCtk',
		embed: 'yzsWw5_CCtk',
		artist: 'Skip Marley',
		song: 'Lions (The Kemist Remix)'
	},
	{
		link: 'https://www.youtube.com/watch?v=mXHy4xfvq1s',
		embed: 'mXHy4xfvq1s',
		artist: 'Zedd & Alessia Cara',
		song: 'Stay (The Kemist Remix)'
	},
	{
		link: 'https://www.youtube.com/watch?v=Fn5j0Ahnq_I',
		embed: 'Fn5j0Ahnq_I',
		artist: 'Jo Jo feat. Remy Ma',
		song: 'Fab (The Kemist Remix)'
	},
]

const ProductionPortfolio = ({dimensions,setModal}) => {
	const [ number, setNumber ] = useState(0);

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return ()=> document.body.style.overflow = 'unset';
	}, []);

	function previousProject() { setNumber(number-1) }
	function nextProject() { setNumber(number+1) }

	function video_display(width) {

		let arr = [];
		for(let i = 0; i < musicObject.length; i++){
			arr.push(
				<div key={i} style={{width:width,height:(width)*0.6,overflow:'hidden'}} className='flexCenter black_background'>
					<YoutubeEmbed link={musicObject[i].embed} width={width-20} height={(width-20)*0.6} />
				</div>
			)
		}


		return(
			<div>
			<div className='flexCenter'>
				<div style={{width:1,height:'100%'}} className='flexCenter'>
					<div>
						<div style={{marginLeft:-60,height:60,width:60}} className='absolute pointer flexCenter' onClick={previousProject}><img src={Chevron} alt='Chevron' style={{height:42}} /></div>
					</div>
				</div>
				<div style={{width:width,height:(width)*0.6,overflow:'hidden'}}
						className='flexCenter pointerx hoverOpacityDownx'>
					{arr[(number+(arr.length*10)) % arr.length]}
				</div>
				<div style={{width:1,height:'100%'}} className='flexCenter'>
					<div>
						<div style={{marginRight:-60,height:60,width:60}} className='absolute pointer flexCenter' onClick={nextProject}><img src={Chevron} alt='Chevron' style={{height:42,transform:'rotate(180deg)'}} /></div>
					</div>
				</div>
			</div>
			{/*<div className='flexLeft' style={{overflow:'scroll',width:width-6,border:'3px solid white'}}>{arr}</div>*/}
			</div>
		)

		// return <div className='flexHeightCenter' style={{flexWrap:'wrap'}}>{arr[0]}</div>



		// let arr = [];
		// for(let i = 0; i < musicObject.length; i++){
		// 	arr.push(
		// 		<div key={i} style={{width:width/3-10-4,height:(width/3-10)*0.65-4,borderRadius:12,overflow:'hidden',border:'2px solid white'}} className='flexCenter black_background'>
		// 			<YoutubeEmbed link={musicObject[i].embed} width={width/3-10-4} height={(width/3-10)*0.65-4} />
		// 		</div>
		// 	)
		// }
		// return <div className='flexHeightCenter' style={{flexWrap:'wrap'}}>{arr}</div>
	}

	let width = 900
	// let height = 900
	if(dimensions.width < 900+80){
		width = dimensions.width-80
	}
	// if(dimensions.height < 900+80){
	// 	height = dimensions.height-80
	// }
	return(
		<div style={{width:width}} className='text_main'>
			<div className='flexRight' style={{width:width}}>
				<div>
					<div className='absolute white_background flexCenter pointer' style={{height:60,width:60,marginLeft:-60,zIndex:20,borderRadius:14}}
							onClick={()=>{setModal('')}}>
						<img src={Cross} alt='Cross' style={{height:28}} />
					</div>
				</div>
			</div>
			<div className='text_sub_hero bold white_background relative flexHeightCenter' style={{boxShadow:'0px 0px 12px rgba(0,0,0,0.2)',height:60,paddingLeft:10}}>Music Production Discography</div>

			<div className='white_colorx' style={{padding:10,}}>
				<div>I love producing and writing music. I was also very lucky to of had some of my music be used by major label artists. Those songs have amassed over 100 million streams.</div>
				<div style={{height:20}}></div>
				{video_display(width)}
			</div>
		</div>
	)
}

export default ProductionPortfolio;

