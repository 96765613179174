// import React, { useState, useEffect, useRef } from 'react';

import ComponentView from '../Components/Components/ComponentView';
import HomeRoute from './HomeRoute';
import NavBar from '../Components/NavBar/NavBar';
import DevelopementPortfolio from '../Components/Portfolios/DevelopementPortfolio';
import EnginePortfolio from '../Components/Portfolios/EnginePortfolio';
import ProductionPortfolio from '../Components/Portfolios/ProductionPortfolio';
import RenderingPortfolio from '../Components/Portfolios/RenderingPortfolio';
import Certificates from '../Components/Certificates/Certificates';

const MainRoute = ({dimensions,scroll,modal,setModal,sound,setSound}) => {


	// // Handle dropdown
	// 	const node = useRef();
	// 	useEffect(()=>{
	// 		function handleClick(e) {
	// 			if(node.current) {
	// 				if(!node.current.contains(e.target)){
	// 					setModal(false);
	// 				}
	// 			}
	// 		}
	// 		document.addEventListener('mousedown', handleClick, false);
	// 		return () => {
	// 			document.removeEventListener('mousedown', handleClick, false);
	// 		}
	// 	},[setModal]);



	function modalDisplay() {
		if(modal === '') return null

		return(
			<div className='modalBack flexCenter'>
				<div className='modalx' style={{padding:0,background:'white',overflow:'hidden',borderRadius:12}}>
					{modal === 'development'
						? <DevelopementPortfolio dimensions={dimensions} setModal={setModal} />
						: null
					}
					{modal === 'engine'
						? <EnginePortfolio dimensions={dimensions} setModal={setModal} sound={sound} setSound={setSound} />
						: null
					}
					{modal === 'production'
						? <ProductionPortfolio dimensions={dimensions} setModal={setModal} />
						: null
					}
					{modal === 'rendering'
						? <RenderingPortfolio dimensions={dimensions} setModal={setModal} />
						: null
					}
					{modal === 'certificates'
						? <Certificates dimensions={dimensions} setModal={setModal} />
						: null
					}
				</div>
			</div>
		)
	}

	return(
		<div>
			<ComponentView dimensions={dimensions} scoll={scroll} color='rgb(210,170,170)' content={ <HomeRoute dimensions={dimensions} scroll={scroll/2} setModal={setModal} /> } />

			<NavBar dimensions={dimensions} setModal={setModal} />

			<div style={{height:16000}}></div>


			{modalDisplay()}
		</div>
	)
}

export default MainRoute;