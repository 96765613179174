import React, { useState, useEffect } from 'react';

import bare_bedroom_6 from '../../Images/Renders/bare_bedroom_6.png';
import cosey_bedroom_10 from '../../Images/Renders/cosey_bedroom_10.png';
import dans_library from '../../Images/Renders/dans_library.png';
import tina_house_render_1 from '../../Images/Renders/tina_house_render_1.png';
import CM_pers_1 from '../../Images/Renders/CM_pers_1.png';
import CM_pers_4 from '../../Images/Renders/CM_pers_4.png';
import CM_pers_9 from '../../Images/Renders/CM_pers_9.png';
import concrete_house_5 from '../../Images/Renders/concrete_house_5.png';
import bedroom1 from '../../Images/Renders/bedroom1.jpg';

import Chevron from '../../Images/chevron-left.svg';
import arrow_left from '../../Images/arrow-left.svg';
import Cross from '../../Images/x.svg';

const image_list = [
	{src:bare_bedroom_6},
	{src:bedroom1},
	{src:dans_library},
	{src:cosey_bedroom_10},
	{src:CM_pers_1},
	{src:tina_house_render_1},
	{src:CM_pers_4},
	{src:concrete_house_5},
	{src:CM_pers_9},
];

const RenderingPortfolio = ({dimensions,setModal}) => {
	const [ selected, setSelected ] = useState(0);

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return ()=> document.body.style.overflow = 'unset';
	}, []);

	function nextProject() {
		let input = selected+1
		if(input >= image_list.length) input = 0
		setSelected(input);
	}
	function previousProject() {
		let input = selected-1
		if(input < 0) input = image_list.length-1
		setSelected(input);
	}

	function imageListDisplay(width) {
		if(selected === ''){
			let arr = [];
			for(let i = 0; i < image_list.length; i++){
				arr.push(
					<div key={i} style={{width:width/3-6,height:(width/3)*0.6-6,border:'3px solid white',borderRadius:8,overflow:'hidden'}}
							className='flexCenter pointer hoverOpacityDownx' onClick={()=>{setSelected(i)}}>
						<div className='zoomInOnHover flexCenter'><img src={image_list[i].src} alt='list_img' style={{width:'120%'}} /></div>
					</div>
				)
			}
			return <div className='flexHeightCenter' style={{flexWrap:'wrap'}}>{arr}</div>
		}
		
		let arr = []
		for(let i = 0; i < image_list.length; i++){
			arr.push(
				<div key={i}>
					<div style={{width:width/4-6,height:(width/4)*0.6-6,overflow:'hidden',border:'3px solid white',borderRadius:8}}
							className='pointer' onClick={()=>{setSelected(i)}}>
						<img src={image_list[i].src} alt='list_img' style={{width:'120%'}} />
					</div>
				</div>
			)
		}
		return(
			<div>
			<div className='flexCenter'>
				<div style={{width:1,height:'100%'}} className='flexCenter'>
					<div>
						<div style={{marginLeft:-60,height:60,width:60}} className='absolute pointer flexCenter' onClick={previousProject}><img src={Chevron} alt='Chevron' style={{height:42}} /></div>
					</div>
				</div>
				<div style={{width:width-6,height:(width)*0.6-6,border:'3px solid white',borderRadius:8,overflow:'hidden'}}
						className='flexCenter pointerx hoverOpacityDownx'>
					<div className='zoomInOnHoverx flexCenter'><img src={image_list[selected].src} alt='list_img' style={{width:'108%'}} /></div>
				</div>
				<div style={{width:1,height:'100%'}} className='flexCenter'>
					<div>
						<div style={{marginRight:-60,height:60,width:60}} className='absolute pointer flexCenter' onClick={nextProject}><img src={Chevron} alt='Chevron' style={{height:42,transform:'rotate(180deg)'}} /></div>
					</div>
				</div>
			</div>
			<div className='flexLeft' style={{overflow:'scroll',width:width-6,border:'3px solid white'}}>{arr}</div>
			</div>
		)
	}
	let width = 900
	// let height = 900
	if(dimensions.width < 900+80){
		width = dimensions.width-80
		// height = dimensions.width-120
	}
	return(
		<div style={{width:width}} className='text_main'>
			<div className='flexRight' style={{width:width}}>
				<div>
					<div className='absolute white_background flexCenter pointer' style={{height:60,width:60,marginLeft:-60,zIndex:20,borderRadius:14}}
							onClick={()=>{setModal('')}}>
						<img src={Cross} alt='Cross' style={{height:28}} />
					</div>
				</div>
			</div>
			<div className='text_sub_hero bold white_background relative flexHeightCenter' style={{boxShadow:'0px 0px 12px rgba(0,0,0,0.2)',height:60,paddingLeft:10}}>Music Production Discography</div>
			{selected === ''
				? null
				: <div className='absolute' style={{zIndex:20}}>
					<div style={{margin:10,height:28,borderRadius:14,paddingLeft:10,paddingRight:14}} onClick={()=>{setSelected('')}}
							className='flexCenter black_background white_color text_smaller bold pointer boxShadow'>
						<img src={arrow_left} alt='arrow_left' style={{width:14,marginTop:1}} />
						Back
					</div>
				</div>
			}
			<div>
				{imageListDisplay(width)}
			</div>
		</div>
	)
}

export default RenderingPortfolio;