import React, { useEffect } from 'react';

import Cross from '../../Images/x.svg';

import './certificate.css';

const certificateObject = [
	{
		link: 'https://www.udemy.com/certificate/UC-PIMJ8MAU/',
		name: 'The Complete Web Developer in 2018: Zero to Mastery',
		image: 'https://udemy-certificate.s3.amazonaws.com/image/UC-PIMJ8MAU.jpg?v=1533471881000',
	},
	{
		link: 'https://www.udemy.com/certificate/UC-3c7f8856-07bf-4f60-a0bf-f8be575ebc2d/',
		name: 'Complete Python Developer in 2023: Zero to Mastery',
		image: 'https://udemy-certificate.s3.amazonaws.com/image/UC-3c7f8856-07bf-4f60-a0bf-f8be575ebc2d.jpg?v=1618044059000',
	},
	{
		link: 'https://www.udemy.com/certificate/UC-b4ab6812-98bf-4776-ad57-37e9b16368ab/',
		name: 'Complete Machine Learning & Data Science Bootcamp 2021',
		image: 'https://udemy-certificate.s3.amazonaws.com/image/UC-b4ab6812-98bf-4776-ad57-37e9b16368ab.jpg?v=1619691479000',
	},
	{
		link: 'https://www.udemy.com/certificate/UC-LDLX4FW4/',
		name: 'Machine Learning in Javascript with Tensorflow.js',
		image: 'https://udemy-certificate.s3.amazonaws.com/image/UC-LDLX4FW4.jpg?v=1569933538000',
	},
	{
		link: 'https://www.udemy.com/certificate/UC-5Y8QI8T5/',
		name: 'Algorithmic Trading In Forex: Create Your First Forex Robot',
		image: 'https://udemy-certificate.s3.amazonaws.com/image/UC-5Y8QI8T5.jpg?v=1521070850000',
	},
	{
		link: 'https://www.udemy.com/certificate/UC-bbcabe5d-5565-4cb5-939d-af4b599a3a92/',
		name: 'Natural Language Processing: NPL With Transformers in Python',
		image: 'https://udemy-certificate.s3.amazonaws.com/image/UC-bbcabe5d-5565-4cb5-939d-af4b599a3a92.jpg?v=1633583360000',
	},
];

const Certificates = ({dimensions,setModal}) => {

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return ()=> document.body.style.overflow = 'unset';
	}, []);

	function certificateLoop(width,height) {
		let array = [];
		for(let i = 0; i < certificateObject.length; i++){
			if(width < 900){
				array.push(
					<div key={i} style={{width:'100%'}} className='flexCenter'>
						<div onClick={()=>{window.open(certificateObject[i].link, '_blank', 'noopener,noreferrer')}}
								className='certificateContainer'>
							<div style={{padding:20,background:'rgb(220,220,220)'}}>
								<div className='text_small'>{certificateObject[i].name}</div>
							</div>
							<img src={certificateObject[i].image} alt='certificate' style={{width:'100%'}} />
						</div>
					</div>
				)
			} else {
				array.push(
					<div key={i} style={{width:'50%'}} className='flexCenter'>
						<div onClick={()=>{window.open(certificateObject[i].link, '_blank', 'noopener,noreferrer')}}
								className='certificateContainer'>
							<div style={{padding:20,background:'rgb(220,220,220)'}}>
								<div className='text_small'>{certificateObject[i].name}</div>
							</div>
							<img src={certificateObject[i].image} alt='certificate' style={{width:'100%'}} />
						</div>
					</div>
				)
			}
		}
		if(width < 900){
			return <div>{array}</div>
		}
		return <div className='flexHeightCenter' style={{flexWrap:'wrap',width:width,height:height-120,overflow:'scroll',paddingBottom:20}}>{array}</div>
	}

	let width = 900
	let height = 900
	if(dimensions.width < 900+120){
		width = dimensions.width-120
	}
	if(dimensions.height < 900+120){
		height = dimensions.height-120
	}

	return(
		<div style={{width:width,height:height}} className='text_main'>
			<div className='flexRight' style={{width:width}}>
				<div>
					<div className='absolute white_background flexCenter pointer' style={{height:60,width:60,marginLeft:-60,zIndex:20,borderRadius:14}}
							onClick={()=>{setModal('')}}>
						<img src={Cross} alt='Cross' style={{height:28}} />
					</div>
				</div>
			</div>
			<div className='text_sub_hero bold white_background relative flexHeightCenter' style={{boxShadow:'0px 0px 12px rgba(0,0,0,0.2)',height:60,paddingLeft:10}}>Certificates and courses</div>

			<div className=''>
				{certificateLoop(width,height)}
			</div>
			<div className='' style={{width:'100%','borderTop':'1px solid rgb(240,240,240)'}}></div>
		</div>
	)
	// return(
	// 	<div>
	// 		<div onClick={()=>{setModal('')}}>close</div>
	// 		{certificateLoop()}
	// 	</div>
	// )
}

export default Certificates;